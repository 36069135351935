import LegislativeIssues from "../Types/LegislativeIssues";
import IssueFilter from "../Types/IssueFilter";


const urlendpoint=process.env.REACT_APP_Urlendpoint;

export function getLegislativeIssues(token): any {

    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}LegislativeIssue/GetIssues`, options)

}

export function getissueById(id: string, token: string): any {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
  return  fetch(`${urlendpoint}LegislativeIssue/GetIssueById/${id}`, options);
}

export function GetHistoryByIssueId(id: string, token: string): any {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
  return  fetch(`${urlendpoint}LegislativeIssue/GetIssueHistoryById/${id}`, options);
}

export function addIssue(data: LegislativeIssues, token: string): any {
    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "POST",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}LegislativeIssue/AddIssue`, options);
}

export function updateIssue(data: LegislativeIssues, token: string): any {
    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "PUT",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}LegislativeIssue/UpdateIssue`, options);
}

export function FilterIssue(data: IssueFilter, token: string): any {

    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "POST",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}LegislativeIssue/FilterIssue`, options);
}


export function GetIssuesExcelData(data: IssueFilter, token: string): any {

    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "POST",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}LegislativeIssue/GetIssuesForExcelAsync`, options);
}

export function getDeletedIssues(token): any {

    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}LegislativeIssue/GetDeleteIssuesAsync`, options)

}

export function RecoverDeletedIssues(ids: number[], token: string): any {

    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "PUT",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(ids)
    };
    return fetch(`${urlendpoint}LegislativeIssue/RecoverDeleteIssuesAsync`, options);
}


export function DeleteIssue(id:number,token: string):any{
    //https://localhost:7267/api/LegislativeIssue/DeleteIssue?id=0
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "Delete",
        headers: headers
    };
  return  fetch(`${urlendpoint}LegislativeIssue/DeleteIssue/${id}`, options);
}