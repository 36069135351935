import React, { useEffect, useState, useContext, forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { SvgIcon, TableHead } from '@mui/material';
import { BlobInfo, BlobListResponce } from '../Types/BlobListResponce.ts';
import { DownloadFileWithName, GetBlobsPaging } from '../Services/DtataUploadService.ts';
import UserContext from '../Contexts/UserContext.ts';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function ExcelFileDisplay({ getBlob }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<BlobListResponce>();
  const userinfo = useContext(UserContext);

  useEffect(() => {
    getBlobs(0,rowsPerPage);
  }, [getBlob]);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log(page, rowsPerPage);
    getBlobs(page, rowsPerPage);
  }, [page, rowsPerPage]);


  const getBlobs = (pageno, pagesize) => {
    debugger;
    GetBlobsPaging(pageno, pagesize, userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          // dataContext.issuesList = resultData.data.result;
          console.log("Retrived issues successfully.", resultData.data);
          debugger;
          setRows(resultData.data);
        }
        else {
          console.log(resultData.message)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  const downloadFile = (filename, downloadname) => {

    DownloadFileWithName(filename, userinfo.accessToken)
      .then(response => response.blob())
      .then(blob => {
        debugger;
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadname);

        document.body.appendChild(link);
        link.click();
        // link.parentNode.removeChild(link);
      })
      .catch(err => console.error('Error downloading the file', err));
  }
const ActualFileName=(name)=> name.substring(0, name.lastIndexOf(' ', name.lastIndexOf('.'))) + name.substring(name.lastIndexOf('.'));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} size='small' aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell style={{minWidth:250}} align="right">Upload Date</TableCell>
            <TableCell align="right">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.blobs.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {ActualFileName(row.name)}
              </TableCell>
              <TableCell align="right">
                {new Date(row.createdOn + 'Z').toLocaleString("en-us", {
                  timeZone: 'America/Chicago',
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
                })}
              </TableCell>
              <TableCell align="right"><SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => downloadFile(row.name, ActualFileName(row.name))} height="40px" viewBox="0 -960 960 960" width="40px" fill="#78A75A"><path d="M480-315.33 284.67-510.67l47.33-48L446.67-444v-356h66.66v356L628-558.67l47.33 48L480-315.33ZM226.67-160q-27 0-46.84-19.83Q160-199.67 160-226.67V-362h66.67v135.33h506.66V-362H800v135.33q0 27-19.83 46.84Q760.33-160 733.33-160H226.67Z" /></svg>
              </SvgIcon></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={rows?.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
