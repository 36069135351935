import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col  from "react-bootstrap/Col";
import  Row  from "react-bootstrap/Row";
import AdminDDLGrid from "../Components/AdminDDLGrid.tsx";
import { GetActiveDDLMenu, GetAllDDLMenu, GetAllDDLValues } from "../Services/AdminService.ts";
import UserContext from "../Contexts/UserContext.ts";
import AdminDDLSideMenu from "../Types/AdminDDLSideMenu.ts";
import BasicData from "../Types/BasicData.ts";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";


export function DropdownCrud() {


  const userinfo = useContext(UserContext);
  const [menuOptions, setMenuOptions] = useState<AdminDDLSideMenu[]>();
  const [options, setOptions] = useState<BasicData[]>();
  const [sideactive, setsideactive] = useState();
  const [activeController, setActiveController] = useState();
  const gridref = useRef<AgGridReactType>(null);
  const [isEditing, setIsEdit] = useState(false);

  useEffect(() => {
    GetActiveDDLSideMenus();

  }, []);

  const GetData = (item) => {
    setIsEdit(false);
    setsideactive(item.id.toString())
    setActiveController(item.controllerName);
    GetAllOptions(item.controllerName);
  };

  const GetAllOptions = (ctrlName) => {
    GetAllDDLValues(ctrlName, userinfo.accessToken)
      .then(response => response.json())
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          console.log("Retrived side successfully.");
          setOptions(resultData.data.result.filter(it => it != null && it.name != null && it.name != '').sort((a, b) => {
            if (a.order !== b.order) {
              return a.order - b.order;
            } else {
              return a.name.localeCompare(b.name);
            }
          }));
        }
        else {
          console.log(resultData.message)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  const GetActiveDDLSideMenus = () => {
    GetActiveDDLMenu(userinfo.accessToken)
      .then(response => response.json())
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          console.log("Retrived side successfully.");
          setMenuOptions(resultData.data.result.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }
        else {
          console.log(resultData.message)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }




  const GetALLDDLSideMenus = () => {
    GetAllDDLMenu(userinfo.accessToken)
      .then(response => response.json())
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          // dataContext.issuesList = resultData.data.result;
          console.log("Retrived side successfully.");
          setMenuOptions(resultData.data.result.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }
        else {
          console.log(resultData.message)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  const addRow = () => {

    gridref.current.api.applyTransaction({ add: [{}], addIndex: 0 });
    setTimeout(() => {
      gridref.current.api.startEditingCell({
        rowIndex: 0,
        colKey: gridref.current.columnApi.getColumns()[0].colId
      });
    });
    setIsEdit(true);
  };

  return <>
    <div className="container-fluid mt-4">
      <Row>
        <Col></Col>
        <Col xs={10} style={{ paddingTop: 0, paddingBottom: 0 }}>
          {options ? <Button className="mybtn btnfitright font12" style={{ marginRight: 80 }} disabled={isEditing} onClick={addRow} >Add New</Button> : ''}
        </Col>
      </Row>
      <div className="row ddladminarea" >
        <Col xs={3} className="side-nav" style={{ overflowY: "scroll" }}>
          <div className="list-group">
            {menuOptions?.map(item =>
              <a href="#" className={`adminsidemenu list-group-item list-group-item-action ${sideactive === item.id.toString() ? 'active' : ''}`} onClick={() => GetData(item)}>{item.name}</a>
            )}
          </div>
        </Col>
        <Col xs={9} className="main-content nonebgcolor">
          <Row style={{ height: "100%", paddingLeft: 15 }}>
            <Col className="gridcol">
              {options ?
                <AdminDDLGrid options={options} controllerName={activeController} setoptions={setOptions} gridref={gridref} setIsEdit={setIsEdit} />
                :
                <Row>
                  <Col></Col>
                  <Col>
                    <p>Select option from side menu</p>
                  </Col>
                  <Col></Col>
                </Row>
              }
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Col>
      </div>
    </div>
  </>
}