import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Navigation } from './Components/Navigation.tsx';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import Issues from './Pages/Issues.tsx';
import WorkingGroup from './Pages/WorkingGroup.tsx';
import { Layout } from './Components/Layout.tsx';
import { Admin } from './Pages/Admin.tsx';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { RoleBasedAccess } from './Components/RoleBaseAccess.tsx';
import { Role } from "./Types/Role.ts"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en';
import UserContext from './Contexts/UserContext.ts';
import "./assets/js/dataTable.js";
import { EditIssue } from './Pages/EditIssue.tsx';
import { SnackbarProvider } from 'notistack';
import AddIssue from './Pages/AddIssue.tsx';
import { TestPage } from './Pages/TestPage';
import IssueHistory from './Pages/IssueHistory.tsx';
import { DeletedIssues } from './Pages/DeletedIssues.tsx';
import { DropdownCrud } from './Pages/DropdownCrud.tsx';
import { ExcelBulkDataUpload } from './Components/ExcelDataUpload.tsx';


function App() {
  const notistackRef =useRef();

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [userinfo, setuserinfo] = useState();

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  function ErrorComponent() {
    return <p>Authentication error...</p>;
  }

  function RequestProfileData() {

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setuserinfo(response);
      });
  }

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  useEffect(() => {
    if (accounts != null && accounts.length > 0) {
      RequestProfileData();
    }
  }, [accounts,!userinfo]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
    <BrowserRouter>
    <SnackbarProvider maxSnack={2} 
    ref={notistackRef}
    action={(key) => (
        <span
            onClick={() => notistackRef.current.closeSnackbar(key)}
            style={{ color: '#fff', fontSize: '20px',cursor:'pointer' }}
        >
            ✖
        </span>
    )}
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
        InteractionStatus={x=>console.log("interaction status",x)}
      >
        <AuthenticatedTemplate>
          {userinfo ?
            <>
            <UserContext.Provider value={userinfo}>
              <Navigation loginInfo={userinfo}></Navigation>
              <Layout>
                <Routes>
                  <Route path='/' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User,Role.External, Role.ReadOnly]} userroles={accounts[0]?.idTokenClaims?.roles}><Issues /></RoleBasedAccess>} />
                  <Route path='/issues' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User,Role.External, Role.ReadOnly]} userroles={accounts[0]?.idTokenClaims?.roles}><Issues /></RoleBasedAccess>} />
                  <Route path='/workinggroup' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User, Role.ReadOnly]} userroles={accounts[0]?.idTokenClaims?.roles}><WorkingGroup /></RoleBasedAccess>} />
                  {/* <Route path='/issueform' element={<IssuesForm />} /> */}
                  <Route path='/issueHistory/:id' element ={<IssueHistory/>}/>
                  {/* <Route path='/issueform/:id' element={<IssuesForm />} /> */}
                  <Route path='/editissue/:id' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User,Role.External, Role.ReadOnly]} userroles={accounts[0]?.idTokenClaims?.roles}><EditIssue /></RoleBasedAccess>} />
                  <Route path='/testpage' element={<RoleBasedAccess rolereq={[Role.Admin]} userroles={accounts[0]?.idTokenClaims?.roles}><TestPage /></RoleBasedAccess>}/>
                  <Route path='/addissue' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User,Role.External]} userroles={accounts[0]?.idTokenClaims?.roles}><AddIssue /></RoleBasedAccess>} />
                  {/* <Route path='/workinggroupform' element={<RoleBasedAccess rolereq={[Role.Admin,Role.User]} userroles={accounts[0]?.idTokenClaims?.roles}><WorkingGroupForm /></RoleBasedAccess>} /> */}
                  <Route path='/grid' element={<testgrid />} />
                  <Route path='/admin' element={<RoleBasedAccess rolereq={Role.Admin} userroles={accounts[0]?.idTokenClaims?.roles}><Admin /></RoleBasedAccess>}>
                  <Route index element={<DropdownCrud/>}/>
                  <Route path='dropdown' element={<DropdownCrud/>}/>
                  <Route path='deletedissues' element={<DeletedIssues/>}/>
                  <Route path='excelupload' element={<ExcelBulkDataUpload/>}/>
                  </Route>
                  <Route path='*' element={<>Page Not Found.</>}/>
                </Routes>
              </Layout>
              </UserContext.Provider>
            </>
            : ''}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <h1>dont have access</h1>
        </UnauthenticatedTemplate>
      </MsalAuthenticationTemplate>
      </SnackbarProvider>
    </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
