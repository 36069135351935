import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { IssueSearchFilter } from "../Components/IssueSearchFilter.tsx";
import { IssueDataGrid } from "../Components/IssueDataGrid.tsx";
import { useNavigate } from "react-router-dom";
import Issuefilter from "../Types/IssueFilter.ts";
import IssueFilter from "../Types/IssueFilter.ts";
import { DeleteIssue, FilterIssue, GetIssuesExcelData } from "../Services/ApiServices.ts";
import UserContext from "../Contexts/UserContext.ts";
import LegislativeIssues from "../Types/LegislativeIssues.js";
// import { useSnackbar } from "notistack";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SvgIcon } from "@mui/material";
import { Close, Dataset, WarningAmber, WarningAmberOutlined, WarningRounded } from "@mui/icons-material";
import { Response } from "../Types/Response.js";
import * as ExcelJs from "exceljs";
import * as fs from "file-saver";
import WarningDialog from "../Components/Warningdialog.jsx";
import { IssueGridData } from "../Types/IssueGridData.js";

// import * as XLSX from "xlsx";

export default function Issues() {
  // const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const [formdata, setformdata] = useState<Issuefilter>({ subject: '', regionId: null, jurisdictionIds: [], statusId: null, keywordIds: [], enforcementFromDate: null, enforcementToDate: null, actionId: null, TypeOfIssueId: null });
  const resetdata = { subject: '', regionId: null, jurisdictionIds: [], statusId: null, keywordIds: [], enforcementFromDate: null, enforcementToDate: null, actionId: null, TypeOfIssueId: null };

  const resetFilter = () => {
    setformdata(resetdata);
    getdata(resetdata);
  }
  const userinfo = useContext(UserContext);
  // const dataContext = useContext(DataContext);
  const [issuelist, setissuelist] = useState<IssueGridData[]>();


  useEffect(() => {
    FilterIssue(formdata, userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          // dataContext.issuesList = resultData.data.result;
          console.log("Retrived issues successfully.");
          setissuelist(resultData.data.result);
        }
        else {
          console.log(resultData.message)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }, []);

  const onChangeHandeler = (event) => {
    setformdata(() =>
      ({ ...formdata, [event.target.name]: event.target.value }));
  };

  const getdata = (data: IssueFilter) => {

    FilterIssue(data, userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          // dataContext.issuesList = resultData.data.result;
          setissuelist(resultData.data.result);
          console.log('IssueList loaded Successfully.');
        }
        else {
          console.log(resultData.message);
        }
      })
      .catch(error => {
        console.log(error);
      }
      );
    console.log(formdata);
  };

  const GetIssuesForExcelAsync = () => {

   return GetIssuesExcelData(formdata, userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          // dataContext.issuesList = resultData.data.result;
          return resultData.data.result;
          console.log('IssueList loaded Successfully.');
        }
        else {
          console.log(resultData.message);
        }
      })
      .catch(error => {
        console.log(error);
      }
      );
    console.log(formdata);
  };

  const editIssue = (id: string) => {
    navigate(`/editissue/${id}`);
  }

  const openDeleteConfirmation = () => { setIsConfirmDelete(true) }

  const closeDeleteConfirmation = () => { setIsConfirmDelete(false) }

  const [deleteId, setDeleteId] = useState(0);

  const deleteIssueById = (id: number) => {
    setDeleteId(id);
    openDeleteConfirmation();
  }

  const deleteissue = () => {
    DeleteIssue(deleteId, userinfo.accessToken)
      .then(response => response.json())
      .then((result: Response<LegislativeIssues>) => {
        if (result.isSuccess) {
          console.log("Issue deleted sucessfully.");
          setissuelist(issuelist?.filter(it => it.id !== deleteId));
          // getdata(resetdata);
        }
        else {
          console.log(result.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
    // if(success){
    //     enqueueSnackbar("Issue deleted sucessfully.", {variant:"success"});
    // }
  }

  const writeexcel = async () => {
    const workbook = new ExcelJs.Workbook();
    const workSheet = workbook.addWorksheet("Issues");
    const excelData:IssueGridData[] = await GetIssuesForExcelAsync();
    workSheet.columns = [
      { header: "Type of Issue", key: "typeofIssue", width: 25 },
      { header: "Subject", key: "subject", width: 50 },
      { header: "Keyword", key: "keyword" , width: 25 },
      { header: "Region", key: "region" , width: 17 },  
      {header:"Business Unit", key: "businessUnit", width:17},
      { header: "Jurisdiction", key: "jurisdiction", width: 17  },
      {header:"County/Districts", key:"county_Districts", width: 17 },
      {header:"State/Province", key:"state_Province",width: 17 },
      {header:"City/Municipalities", key:"city_Municipalities", width: 17 },
      { header: "Enforcement", key: "enforcement", width: 17  },
      { header: "Status", key: "status" , width: 17 },
      { header: "Action", key: "action", width: 25  },
      { header: "Enforcement Details", key: "enforcementDetails", width: 50},
      { header: "Category", key: "category" , width: 25 },
      {header:"URL", key:"url", width: 50 },
      {header:"Additional URL", key:"additionalURL", width: 50 }
    ];

    excelData?.map((data) => {
      workSheet.addRow({
        typeofIssue: data.typeofIssue.name,
        subject: data.subject,
        keyword: data.keywords?.map(it => it.name).join(', '),
        region: data.region.name,   
        businessUnit: data.businessUnit?.name,
        jurisdiction: data.jurisdictions?.map(it => it.name).join(', '),
        county_Districts: data.county_Districts,
        state_Province: data.state_Province,
        city_Municipalities: data.city_Municipalities,
        enforcement: data.enforcementDate && new Date(data.enforcementDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' }),
        status: data.status.name,
        action: data.action.name,
        enforcementDetails: data.enforcementDetails,
        category: data.categories?.map(it => it.name).join(', '),
        url:data.url,
        additionalURL:data.additionalURL
      });
    });
    if (workSheet.getRow(1) != undefined) {
      workSheet.getRow(1).font = { bold: true };
    }

    workSheet.eachRow((row)=>row.eachCell(c=>c.alignment = {vertical:'top', wrapText:true}));

    workbook.xlsx.writeBuffer()
      .then(buffer => fs.saveAs(new Blob([buffer]), `IssueList_${new Date(Date.now()).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })}_${new Date(Date.now()).getHours()}${new Date(Date.now()).getMilliseconds()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err));

  }

  const GoToHistory=(id: string)=>{
    navigate(`/issueHistory/${id}`);
  }

  return <>
    <Container fluid className="issuecontainer">
      <Row>
        <h1 className="pageHeading" >Issue List</h1>
      </Row>
      <Row className="boxborder" style={{padding:0}}>
        <IssueSearchFilter formdata={formdata} onChangeHandeler={onChangeHandeler} getdata={getdata} resetFilter={resetFilter} />
      </Row>
      <Row className=" boxborder" style={{ marginTop: 24, fontSize: 12,padding:0 }}>
        <IssueDataGrid data={issuelist} gridref={gridRef} editIssue={editIssue} deleteissue={deleteIssueById} writeExcel={writeexcel} GoToHistory={GoToHistory} />
      </Row>
    </Container >
    <WarningDialog opendeleteconfirmation={openDeleteConfirmation} closedeleteconfirmation={closeDeleteConfirmation} isdeleteconfirm={isConfirmDelete} deleteitem={deleteissue}/>

  </>
}
