import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IssuesForm from "./IssuesForm.tsx";
import LegislativeIssues from "../Types/LegislativeIssues.ts";
import { getissueById, updateIssue } from "../Services/ApiServices.ts";
import UserContext from "../Contexts/UserContext.ts";
import { Response } from "../Types/Response.ts";
import { Role } from "../Types/Role.ts";

export function EditIssue() {
    const [load,setload]=useState(false);
    const { id } = useParams();
    const navigation = useNavigate();
    const goBack = () => {
        navigation("/");
    }

    const [initialValues, setinitialValues] = useState<LegislativeIssues>();
    const userinfo = useContext(UserContext);
    const [isReadonly, setIsReadonly] = useState(false);



    useEffect(() => {
        setIsReadonly(userinfo?.idTokenClaims.roles.includes(Role.ReadOnly));

        getissueById(id ?? '', userinfo.accessToken)
            .then(response => response.json())
            .then((result: Response<LegislativeIssues>) => {
                if (result.isSuccess) {
                    console.log("Issue retrived sucessfully.",result.data);
                    result.data.enforcementDetails = result.data.enforcementDetails || "";
                    setinitialValues(result.data);
                }
                else {
                    console.log(result.message);
                }
            })
            .catch(error =>{  console.log(error)});

    }, []);

    const UpdateIssue = (data:LegislativeIssues) => {
        data.updatedBy=userinfo.account?.username??'';
        data.updatedDate=new Date().toISOString();
        updateIssue(data, userinfo.accessToken)
            .then(response => response.json())
            .then((result: Response<LegislativeIssues>) => {
                if (result && result.isSuccess) {
                    console.log("Issue updated sucessfully.");
                    goBack();
                }else {
                    console.log(result.message);
                }
            })
            .catch(error=>{
                console.log(error)
            })
    }

    return <>
        <h1 className="pageHeading">Edit Issue</h1>
        <IssuesForm initValues={initialValues} userinfo={userinfo} submitData={UpdateIssue} goTo={goBack} isEdit={true} isReadonly={isReadonly} />

    </>
}