import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import WorkingGroupGrid from "../Components/WorkingGroupGrid.tsx";
import WorkingGroupFilter from "../Components/WorkingGroupFilter.tsx";
import { Close, WarningAmberRounded } from "@mui/icons-material";
import WorkingGroupForm from "../Components/WorkingGroupForm.tsx";
import workGroupFilter from "../Types/WorkGroupFilter.ts";
import { DeleteWorkingGroup, GetFilteredData, UpdateWorkingGroup, addAsync, getById } from "../Services/WorkingGroupService.ts"
import UserContext from "../Contexts/UserContext.ts";
import { Response } from "../Types/Response.js";
import { Workinggroup } from "../Types/Workinggroup.js";
import { ObjList } from "../Types/ObjList.js";
import { Button } from "react-bootstrap";
import WarningDialog from "../Components/Warningdialog.jsx";
import * as ExcelJS from "exceljs";
import * as fs from "file-saver";
import { Role } from "../Types/Role.ts";

export default function WorkingGroup() {
    const initialFilter: workGroupFilter = { subject: '', StatusId: 0, jurisdictionIds: [], openDate: null, closedDate: null, content: '' };

    const initialWorkingGroup: Workinggroup = { id: 0, subject: '', statusId: 0, jurisdictionIds: [], status: null, workingGroupJurisdiction: [], openDate: null, closedDate: null, content: '', createdBy: '', createdDate: new Date(), updatedDate: null, updatedBy: '' };
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [addModelOpen, setAddModelOpen] = useState(false);
    const [workingGroups, setWorkingGroups] = useState<Workinggroup[]>([]);
    const [workingGroup, setWorkingGroup] = useState<Workinggroup>(initialWorkingGroup);
    const closeModel = () => setAddModelOpen(false);
    const openModel = () => setAddModelOpen(true);
    const [filter, setFilter] = useState<workGroupFilter>(initialFilter);
    const [isDeleteConfirm, setisDeleteConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [isReadonly, setIsReadonly] = useState(false);


    const openDeleteConfirmation = () => setisDeleteConfirm(true);
    const closeDeleteConfirmation = () => setisDeleteConfirm(false);

    const filterReset = () => {
        setFilter(initialFilter);
        filterData(filter);
    };

    const onChangeHandeler = (event) => {
        setFilter(() =>
            ({ ...filter, [event.target.name]: event.target.value }));
    };

    const onChangeFormHandeler = (event) => {
        setWorkingGroup(() =>
            ({ ...workingGroup, [event.target.name]: event.target.value }));
    };

    const userinfo = useContext(UserContext);

    useEffect(() => {
        setIsReadonly(userinfo?.idTokenClaims.roles.includes(Role.ReadOnly));

        GetFilteredData(filter, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then((resultData: Response<ObjList<Workinggroup>>) => {
                if (resultData && resultData.isSuccess) {
                    console.log("Added workgroup successfully.");
                    setWorkingGroups(resultData.data.result)
                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });

    }, []);
    const filterData = (data) => {

        GetFilteredData(data, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then((resultData: Response<ObjList<Workinggroup>>) => {
                if (resultData && resultData.isSuccess) {
                    console.log("retrived workgroup successfully.");
                    setWorkingGroups(resultData.data.result)
                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const OpenForUpdate = (id) => {

        getById(id, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then((resultData: Response<Workinggroup>) => {
                if (resultData && resultData.isSuccess) {
                    console.log("retrived workgroup successfully.");
                    setWorkingGroup(resultData.data);
                    openModel();
                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const UpdateWorkGroup = (data) => {

        UpdateWorkingGroup(data, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then((resultData: Response<Workinggroup>) => {
                if (resultData && resultData.isSuccess) {
                    console.log("workgroup updated successfully.");
                    setWorkingGroup(initialWorkingGroup);
                    filterData(filter);
                    closeModel();

                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const SaveWorkingGroup = (data: Workinggroup) => {
        data.createdBy = userinfo.account?.username ?? '';
        data.createdDate = new Date();
        data.updatedDate = new Date();

        addAsync(data, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    console.log("Added workgroup successfully.");
                    filterData(filter);
                    closeModel();
                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const deleteById = (id: number) => {
        setDeleteId(id);
        openDeleteConfirmation();
    }

    const deleteitem = () => {
        DeleteWorkingGroup(deleteId, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    console.log("workgroup deleted successfully.");
                    filterData(filter);
                }
                else {
                    console.log(resultData.message)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const submitData = (data) => {
        isEdit ?
            UpdateWorkGroup(data) :
            SaveWorkingGroup(data);
    }


    const writeWorkingGroupExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const workSheet = workbook.addWorksheet("Issues");
    
        workSheet.columns = [
          { header: "Subject", key: "subject", width: 50 },
          { header: "Content", key: "content" , width: 50 },
          { header: "Status", key: "status" , width: 17 },
          { header: "Jurisdiction", key: "jurisdiction", width: 20  },
          { header: "OpenDate", key: "openDate" , width: 17 },
          { header: "CloseDate", key: "closeDate", width: 17  }
        ];
    
        workingGroups?.map((data) => {
          workSheet.addRow({
            subject: data.subject,
            content: data.content,
            status: data.status && data.status.name,
            openDate: data.openDate && new Date(data.openDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' }),
            closeDate: data.closedDate && new Date(data.closedDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' }),
            jurisdiction: data.workingGroupJurisdictions.map(it => it.jurisdiction.name).join(', ')
          });
        });
        if (workSheet.getRow(1) != undefined) {
          workSheet.getRow(1).font = { bold: true };
        }
    
        workSheet.eachRow((row)=>row.eachCell(c=>c.alignment = {vertical:'top', wrapText:true}));
    
        workbook.xlsx.writeBuffer()
          .then(buffer => fs.saveAs(new Blob([buffer]), `WorkingGroup${new Date(Date.now()).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })}_${new Date(Date.now()).getHours()}${new Date(Date.now()).getMilliseconds()}.xlsx`))
          .catch(err => console.log('Error writing excel export', err));
    
      }

    return <>
        <div className="container-fluid wrkgrkcontainer">
            <Row>
                <h1 className="pageHeading">Working Group</h1>
            </Row>
            <Row className="boxborder">
                <WorkingGroupFilter data={filter} onChangeHandeler={onChangeHandeler} submitData={filterData} resetdata={filterReset} initdata={initialFilter} />
            </Row>
            <Row className="boxborder wrkgrpgridcontainer" style={{ marginTop: 15 }}>
                <WorkingGroupGrid addmodel={() => { setWorkingGroup(initialWorkingGroup); openModel(); setIsEdit(false); }} writeExcel={writeWorkingGroupExcel} data={workingGroups} getdatabyid={(id) => { setIsEdit(true); OpenForUpdate(id); }} deletItem={deleteById} isReadonly={isReadonly} />
            </Row>
        </div>

        <Dialog
            disableEscapeKeyDown={true}
            onClose={(e, r) => { r && r === "backdropClick" ? openModel() : closeModel() }}
            aria-labelledby="customized-dialog-title"
            id="wgDialog"
            open={addModelOpen}
            fullWidth={true}
            style={{ maxHeight: "none", minHeight: 420,maxWidth:"none", minWidth:1000}}
            disableScrollLock={true}
            maxWidth="md"
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <h1 className="font12">{isEdit ? 'Edit' : 'Add'} WorkingGroup</h1>
                {closeModel ? (
                    <IconButton
                        aria-label="close"
                        onClick={closeModel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <WorkingGroupForm closePopModel={() => { closeModel(); setIsEdit(false); }} initialVal={workingGroup} onChangeHandeler={onChangeFormHandeler} submitData={(data) => { submitData(data) }} isReadonly={isReadonly} />
            </DialogContent>
            {/* <DialogActions>
                <Button autoFocus size="sm" variant="info" className="mybtn font12" onClick={() => { closeModel(); }}>
                    Cancel
                </Button>
                <Button className="mybtn font12" size="sm" variant="success" autoFocus onClick={() => { closeModel() }}>
                    Save
                </Button>
            </DialogActions> */}
        </Dialog>

        <WarningDialog opendeleteconfirmation={openDeleteConfirmation} closedeleteconfirmation={closeDeleteConfirmation} isdeleteconfirm={isDeleteConfirm} deleteitem={deleteitem} />

    </>
}