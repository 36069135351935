//https://localhost:7267/api/Admin/GetAllDDLSideMenu

import BasicData from "../Types/BasicData";

const urlendpoint=process.env.REACT_APP_Urlendpoint;



export function GetAllDDLMenu(token): any {
debugger;
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Admin/getallasync`, options)
}

export function GetAllDDLValues(controller,token): any {
        const headers = new Headers();
    
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append('Accept', 'application/json, text/plain');
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        const options = {
            method: "GET",
            headers: headers
        };
        return fetch(`${urlendpoint}${controller}/GetAllAsync`, options)
    }

    export function UpdateDDL(controller,data: BasicData, token: string): any {
        var headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append('Accept', 'application/json, text/plain');
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        const options = {
            method: "PUT",
            headers: headers,
            ContentType: 'application/json',
            body: JSON.stringify(data)
        };
        return fetch(`${urlendpoint}${controller}/UpdateDataAsync`, options);
    }

    export function DeleteDDL(controller,data: BasicData, token: string): any {
        var headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append('Accept', 'application/json, text/plain');
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        const options = {
            method: "DELETE",
            headers: headers,
            ContentType: 'application/json',
            body: JSON.stringify(data)
        };
        return fetch(`${urlendpoint}${controller}/DeleteDataAsync`, options);
    }

    
    export function AddDDL(controller,data: BasicData, token: string): any {
        var headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append('Accept', 'application/json, text/plain');
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        const options = {
            method: "POST",
            headers: headers,
            ContentType: 'application/json',
            body: JSON.stringify(data)
        };
        return fetch(`${urlendpoint}${controller}/AddDataAsync`, options);
    }

export function GetActiveDDLMenu(token): any {

    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Admin/getactivedata`, options)
}
