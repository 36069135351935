

const urlendpoint=process.env.REACT_APP_Urlendpoint;

export async function getRegions(token): Promise<any> {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Region/getactivedata`, options);
}

export function getCountries(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Country/getactivedata`, options)
}


export function getAllCategories(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Category/getactivedata`, options)
}


export function getAllCustParticipation(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}CustomerParticipation/getactivedata`, options)
}


export function getAllImpactServiceLine(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}ImpactServiceLine/getactivedata`, options)
}



export function getAllActions(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}IssueAction/getactivedata`, options)
}



export function getIssueStatus(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}IssueStatus/getactivedata`, options)
}

export function getAllKeyWords(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Keyword/getactivedata`, options)
}



export function getAllLegislativeStatuses(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}LegislativeStatus/getactivedata`, options)
}


export function getAllTypeofEmergingIssues(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}TypeofEmergingIssue/getactivedata`, options)
}

export function getAllCustomers(token) {
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Customer/getactivedata`, options)
}

export function GetJurisdictions(token){
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}Jurisdiction/getactivedata`, options)
}

export function GetWorkingGroupStatuses(token){
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}WorkingGroupStatus/getactivedata`, options)
}



export function GetBusinessUnits(token){
    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}BusinessUnit/getactivedata`, options)
}