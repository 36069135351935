import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DataContext from "../Contexts/DataContext.js";
import { Button, Col, Row } from "react-bootstrap";
import { Archive, DeleteForeverOutlined, InfoOutlined } from "@mui/icons-material";
import { green, orange, pink, yellow } from "@mui/material/colors";
import { CsvExportParams, GridApi, GridOptions } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import UserContext from "../Contexts/UserContext.ts";
import { AuthenticationResult } from "@azure/msal-common";
import { Role } from "../Types/Role.ts";



export function IssueDataGrid({ data, editIssue, deleteissue, writeExcel, gridref, GoToHistory }) {


    // useEffect(() => { console.log(userinfo) }, []);
    // const getvalues = (params) => {
    //     return params.value.map(it => it.name).join(', ');
    // }
    const arrtest: string[] = [];
    const valueGetters = (params) => {
        // return JSON.stringify(params.data[params.colDef.field].map(it => it.name).join(', '));
        return params.data[params.colDef.field]?.map(it => it.name).join(', ');
    };
    const userinfo: AuthenticationResult = useContext(UserContext);
    const [columndefs] = useState([
        // { field: "id",headerName:'AIM ID',width:'100'},
        { field: "shortSubject", headerName: 'Subject', tooltipField: 'shortSubject', width: 350,filter:true },
        { field: "keywords", headerName: 'Keywords', flex: 1, valueGetter: params => valueGetters(params) ,filter:true}, 
        { field: 'region.name', headerName: 'Region', width: 100, flex: 0.6 ,filter:true},
        { field: "jurisdictions", headerName: 'Jurisdiction', flex: 0.8, valueGetter: params => valueGetters(params) ,filter:true},
        { field: 'enforcementDate', headerName: 'Enforcement Date', headerClass: 'textcenter', filter:'agDateColumnFilter' , flex: 0.82, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        { field: 'status.name', headerName: 'Status', width: 100,  flex: 0.6,filter:true  },
        { field: 'action.name', headerName: 'Action',  flex: 0.97 ,filter:true},
        { field: 'enforcementDetails', headerName: 'Enforcement Details', headerClass: 'textcenter', minwidth: '150', tooltipField: 'enforcementDetails', flex: 1,filter:true },
        { field: "categories", headerName: 'Categories', valueGetter: params => valueGetters(params), flex: 0.8 ,filter:true},
        {
            field: 'Delete',
            filter: false,
            sortable: false,
            headerClass:"font10",
            hide:userinfo.idTokenClaims.roles.includes(Role.ReadOnly),
            //  pinned: 'right', 
            width: 75, cellRenderer: (params) =><>{userinfo.idTokenClaims.roles.includes(Role.ReadOnly) ? null : <span>
                <DeleteForeverOutlined className="pointerCurser" onClick={() => { deleteissue(params.data.id); }} sx={{ fontSize: 16, color: pink[500] }} />
            </span>}
            </>
        },
        {
            field: 'History',
            filter: false,
            sortable: false,
            headerClass:"font10",
            hide:userinfo.idTokenClaims.roles.includes(Role.External),
            //  pinned: 'right', 
            width: 77, cellRenderer: (params) =><>
            {userinfo.idTokenClaims.roles.includes(Role.External) ? null :
             <span>
                    <Archive className="pointerCurser" onClick={() => { GoToHistory(params.data.id); }} sx={{ fontSize: 16, color: green[500] }} />
            </span>
            }</>
        },

    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true
        , filter: false
        , wrapHeaderText: true
        // ,floatingFilter:true
        // ,resizable:true
    }), []);

    const oncellclick = (e) => {
        editIssue(e.data.id);
    }

    const rowHeight = 24;

    const perPage = 14;
    const [gridApi, setGridApi] = useState<GridApi>();
    const OnGridReady = props => {
        setGridApi(props.api);
    }

    const navigate = useNavigate();

    return <>
        <Row>
            <Col style={{ paddingTop: 0, paddingBottom: 0 }}>
            {userinfo.idTokenClaims.roles.includes(Role.ReadOnly) ? null :   <Button className="mybtn btnfitright font12" onClick={() => { navigate('/addissue') }} >Add New</Button>}

                {userinfo.idTokenClaims.roles.includes(Role.External) ? null :  <svg className="mybtn btnfitright pointerCurser" xmlns="http://www.w3.org/2000/svg" onClick={writeExcel} viewBox="0 0 48 48" width="24px" height="24px"><path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z" /><path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z" /><path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z" /><path fill="#17472a" d="M14 24.005H29V33.055H14z" /><g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z" /><path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z" /><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z" /><path fill="#129652" d="M29 24.005H44V33.055H29z" /></g><path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z" /><path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z" /></svg>}
            </Col>
        </Row>
        <Row className="ag-theme-alpine issuegrd" style={{ marginTop: 11,height:445,padding:0 }}>
            <AgGridReact
                defaultColDef={defaultColDef}
                rowData={data}
                animateRows={true}
                paginationPageSize={perPage}
                pagination={true}
                rowHeight={rowHeight}
                headerHeight={38}
                onCellDoubleClicked={oncellclick}
                enableBrowserTooltips={true}
                columnDefs={columndefs}
                onGridReady={OnGridReady}
                suppressMovableColumns={true}
            />
        </Row>
    </>
}
