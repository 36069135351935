import { useMsal } from "@azure/msal-react";
import React from "react";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import "../assets/js/main.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AuthenticationResult } from "@azure/msal-common";
import { Role } from "../Types/Role.ts";


export function Navigation(prop) {

    const loginInfo: AuthenticationResult = prop.loginInfo;
    const roles: string[] | null = loginInfo?.account?.idTokenClaims?.roles ?? null;
    const { instance } = useMsal();
    const location = useLocation();

    return (
        <>
            <header id="header" className="d-flex align-items-center navigation" style={{ padding: 0 }}>
                <span className="logobg">
                    <img
                        src="../TMSLogo.png"
                        width="100"
                        height="39"
                        className="d-inline-block align-top"
                        alt="TMSHaviLogo"
                    />
                </span>
                <div className="container d-flex align-items-center justify-content-between " >
                    <h1 className="logo mr-auto"><a>Anticipatory Issues Management</a></h1>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><Link className={"nav-link scrollto" + ((location.pathname === "/issues" || location.pathname === "/") ? " active" : '')} to="/issues" >Issue List</Link></li>
                            {(roles == null || roles.some(it => [Role.Admin.toString(), Role.User, Role.ReadOnly].includes(it))) ?
                                <li><Link className={"nav-link scrollto" + (location.pathname === "/workinggroup" ? " active" : '')} to="/workinggroup" >Working Group</Link></li>
                                : ""}
                            {(roles == null || roles?.indexOf(Role.Admin) < 0) ? '' :
                                // <li><Link className={"nav-link scrollto" + (location.pathname === "/admin" ? " active" : '')} to="/admin" >Admin</Link></li>
                                <li className="dropdown"><a href="#"><span>Admin</span> <i className="bi bi-chevron-down"></i></a>
                                    <ul>
                                        <li><Link to="/admin/dropdown">Dropdown</Link></li>
                                        <li><Link to="/admin/deletedissues" >Deleted Issues</Link></li>
                                        <li><Link to="/admin/excelupload">Data upload</Link></li>
                                    </ul>
                                </li>
                            }
                            <li>

                            </li>
                            <li className="dropdown">
                                <a >
                                    <i style={{ fontSize: 12, color: "white" }} className="icon bi-person-circle"> <span style={{ margin: 4 }}>{loginInfo?.account?.name ?? 'Guest'}</span><i className="icon bi-chevron-down"></i></i>
                                </a>
                                <ul style={{ padding: 0 }}>
                                    <Button variant="warning"
                                        onClick={() => {
                                            instance.logoutPopup({
                                                postLogoutRedirectUri: "localhost:3000"
                                            });
                                        }}
                                    >Logout</Button>
                                </ul>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>
        </>
    );
}