import { Input, InputLabel, FormControl, MenuItem, Select, TextField, OutlinedInput, Checkbox, ListItemText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import workGroupFilter from "../Types/WorkGroupFilter";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import WorkingGroupStatus from "../Types/WorkingGroupStatus.ts";
import Jurisdiction from "../Types/Jurisdiction.ts";
import { GetJurisdictions, GetWorkingGroupStatuses } from "../Services/GetDropDownsData.ts";
import UserContext from "../Contexts/UserContext.ts";

export default function WorkingGroupFilter({ data, onChangeHandeler, submitData, resetdata, initdata }) {
  const filter: workGroupFilter = data;
  const [statuses, setStatuses] = useState<WorkingGroupStatus[]>([]);

  const [jurisdictions, setJurisdiction] = useState<Jurisdiction[]>([]);

  const userinfo = useContext(UserContext);


  useEffect(() => {

    GetJurisdictions(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          var data = (resultData.data.result as Jurisdiction[]).filter(it => it != null && it.name != null && it.name != '');
          setJurisdiction(data.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
          console.log(jurisdictions, filter);
        }
      })
      .catch(error => console.log(error));

    GetWorkingGroupStatuses(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setStatuses(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));


  }, []);


  const [openDatebox, setOpenDatebox] = useState(false);
  const [closeDatebox, setCloseDatebox] = useState(false);




  return <>
    <Form className="container textcenter wkfilter">
      <Row style={{ justifyContent: "center" }}>
        <Col xs="3">
          <Row className="padding10">
            <FormControl fullWidth className="ctrlsize">
              <TextField
                value={filter.subject}
                label="Subject"
                name="subject"
                onChange={onChangeHandeler}
                id="outlined-size-small"
                size="small"
                InputProps={
                  {
                    className: "font12 ctrlsize"
                  }}
              />
            </FormControl>
          </Row>
          <Row className="padding10">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label" className="labelbgwhite">Jurisdiction</InputLabel>
              <Select
                size="small"
                labelId="demo-multiple-checkbox-label"
                id="jurisdiction"
                multiple
                name="jurisdictionIds"
                value={filter.jurisdictionIds}
                onChange={onChangeHandeler}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => jurisdictions.filter(it => filter.jurisdictionIds && filter.jurisdictionIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                MenuProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom'
                  }
                }}
              >
                {jurisdictions.map((option) => (
                  <MenuItem key={option.id} value={option.id} style={{padding:0,paddingLeft:16}}>
                    <Checkbox checked={filter.jurisdictionIds.indexOf(option.id) > -1} style={{padding:6}}/>
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
        </Col>
        <Col>
          <Row className="padding10">
            <FormControl fullWidth>
              <TextField
                value={filter.content}
                label="Content"
                name="content"
                onChange={onChangeHandeler}
                id="outlined-size-small"
                size="small"
                InputProps={
                  {
                    className: "font12 ctrlsize",
                  }}
              />
            </FormControl>
          </Row>
          <Row className="padding10">
            <FormControl fullWidth>
              <InputLabel id="outlined-size-small-label">Status</InputLabel>
              <Select
                size="small"
                labelId="outlined-size-small-label"
                id="WStatus"
                value={filter.StatusId}
                label="Status"
                name="StatusId"
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                onChange={onChangeHandeler}
                MenuProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom'
                  },
                  PaperProps:{sx:{height:12}}
                }}
              >
                {
                  statuses.map(it => (
                    <MenuItem value={it.id}>{it.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Row>
        </Col>
        <Col>
          <Row className="padding10">
            <FormControl id="searchdate" fullWidth>
              <DatePicker
                label="Open Date"
                open={openDatebox}
                onOpen={() => setOpenDatebox(true)}
                onClose={() => setOpenDatebox(false)}
                value={filter.openDate ? filter.openDate : null}
                onChange={(date) => { filter.openDate = date }}
                format="ll"
                // inputProps={{ MenuProps: { disableScrollLock: true } }}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    id: "opendate",
                    className: "font12",
                    onKeyDown: (e) => e.preventDefault(),
                    onClick: (e) => { setOpenDatebox(true) }
                  }
                }}
              />
            </FormControl>
          </Row>
          <Row className="padding10">
            <FormControl id="searchdate" fullWidth>
              <DatePicker
                label="Close Date"
                open={closeDatebox}
                onOpen={() => setCloseDatebox(true)}
                onClose={() => setCloseDatebox(false)}
                value={filter.closedDate ? filter.closedDate : null}
                onChange={(date) => { filter.closedDate = date }}
                format="ll"
                inputProps={{ MenuProps: { disableScrollLock: true} }}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    id: "closedate",
                    className: "font12",
                    onKeyDown: (e) => e.preventDefault(),
                    onClick: (e) => { setCloseDatebox(true) }
                  }
                }}
              />
            </FormControl>
          </Row>
        </Col>
        <Col style={{ alignSelf: "center" }}>
          <Button className="mybtn font12" style={{ width: "auto" }} onClick={() => submitData(filter)}>Search</Button>
          <Button className="mybtn font12" style={{ width: "auto" }} onClick={() => { resetdata(); submitData(initdata) }}>Reset</Button>
        </Col>
      </Row>

    </Form>
  </>
}