import React from "react";
import LegislativeIssues from "../Types/LegislativeIssues";
import { ObjList } from "../Types/ObjList";
import { WorkingGroup } from "../Types/WorkingGroup";
import { Response } from "../Types/Response";



export interface LegislativeDataContext {
    issue: LegislativeIssues,
    issuesList: LegislativeIssues[],
    workingGroup: WorkingGroup,
    workingGroupList: WorkingGroup[],
    // ,
    // actionDetails:ActionDetail,
    // customerIssueDetails:CustomerIssueDetail
}

export const LegislativeData: LegislativeDataContext = {
    issue:  {
            aimid: 0, id: 0, createdDate: '', updatedDate: '', typeofEmergingIssue: { id: 0, name: '' }, region: { regionId: 0, name: '' },
            country: { id: 0, name: '' }, state_Province: '', county_Districts: '', city_Municipalities: '', keyword: [], enforcementDetails:'',
            subject: '', enforcementDate: '', actionDetails: [], category: [], impactforServiceLine: [], action: { id: 0, name: '' },
            url: '', status: { id: 0, name: '' }, opentoCustomer: true, createdBy: '', dueDate: null, impactforServiceLineIds: [], categoryIds: [], keywordIds: [],
            legislativeStatus: { id: 0, name: '' }, customerParticipation: { id: 0, name: '' }, updatedBy: '', emergingIssueId: 0, statusId:0, actionId: 0, customerParticipationId: 0, regionId: 0, countryId: 0, legislativeStatusId: 0
        
    },
    issuesList: [] ,
    workingGroup: { subject: '', status: '', content: '', jurisdiction: '', openDate: new Date(), closedDate: new Date() },
    workingGroupList:  [] 
    // ,
    // customerIssueDetails:{ Id:'', IssueId:'', CustomerId:'', ActionDetails:[],Customer:null,LegislativeIssue:null},
    // actionDetails:{Id:'', Action:'',Created:'', Updated:'', CreatedBy:'', UpdatedBy:'', CustomerIssueDetailId:''}
}

const DataContext = React.createContext<LegislativeDataContext>(LegislativeData);
export default DataContext;