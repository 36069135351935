import React, { useState, useEffect, useContext } from "react";
import { Container} from "react-bootstrap";
import DataContext from "../Contexts/DataContext.tsx";
import LegislativeIssues from "../Types/LegislativeIssues.ts";
import { WorkingGroup } from "../Types/WorkingGroup.js";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import UserContext from "../Contexts/UserContext.ts";
import { ObjList } from "../Types/ObjList.ts";
import { getLegislativeIssues } from "../Services/ApiServices.ts";
import { Response } from "../Types/Response.js";


interface Props {
    children?: React.ReactNode;
}

export function Layout(props: Props) {

    const userinfo = useContext<AuthenticationResult>(UserContext);


    const [issueData, setIssuedata] = useState<LegislativeIssues>({
            aimid: 0, id: 0, createdDate: '', updatedDate: '', typeofEmergingIssue: { id: 0, name: '' }, region: { regionId: 0, name: '' },
            country: { id: 0, name: '' }, state_Province: '', county_Districts: '', city_Municipalities: '', keyword: [],
            subject: '', enforcementDate: '', enforcementDetails:'',actionDetails: [], category: [], impactforServiceLine: [], action: { id: 0, name: '' },
            url: '', status: { id: 0, name: '' }, opentoCustomer: true, createdBy: '', dueDate: null, impactforServiceLineIds: [], categoryIds: [], keywordIds: [],
            legislativeStatus: { id: 0, name: '' }, customerParticipation: { id: 0, name: '' }, updatedBy: '', emergingIssueId: 0, statusId: 0, actionId: 0, customerParticipationId: 0, regionId: 0, countryId: 0, legislativeStatusId: 0
        }
    );

    const [issueList, setIssueList] = useState<LegislativeIssues[]>([]);

    const [workingGroupData, setworkingGroupData] = useState<WorkingGroup>({
            subject: '', status: '', content: '',
            jurisdiction: '', openDate: new Date(), closedDate: new Date()
        }
    );

    const [workingGroupList, setworkingGroupList] = useState<WorkingGroup[]>([]);

    // useEffect(() => {
    //     //setIssueList
    //     getLegislativeIssues(userinfo.accessToken)
    //         .then(response => response.json())
    //         .then((result: Response<ObjList<LegislativeIssues>>) => {
    //             if (result && result.isSuccess) {
    //                 setIssueList(result.data.result);
    //             }
    //             else {
    //                 console.log(result.message);
    //             }
    //         }
    //         )
    //         .catch(error => console.log(error));

    //     //setworkingGroupList

    // }, []);

    const issueDataContext = {
        issue: issueData,
        issuesList: issueList,
        workingGroup: workingGroupData,
        workingGroupList: workingGroupList,
teststring:issueList.toString()
    }

    return <>
        <DataContext.Provider value={issueDataContext}>
            <Container fluid className="maincontent">
                {props.children}
            </Container>
        </DataContext.Provider>
    </>;
}
