import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationResult } from "@azure/msal-common";
import Restore from "@mui/icons-material/Restore";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "../Contexts/UserContext.ts";
import LegislativeIssues from "../Types/LegislativeIssues.ts";
import { RecoverDeletedIssues, getDeletedIssues } from "../Services/ApiServices.ts";
import { RestoreIssues } from "../Types/IssueGridData.ts";

export function DeletedIssues() {


    const [data, setdata] = useState<LegislativeIssues[]>([]);
    const userinfo: AuthenticationResult = useContext(UserContext);


    useEffect(() => {
        getIssues();
    }, []);


    const getIssues = () => {
        getDeletedIssues(userinfo.accessToken).then(response =>
            response.json()
        ).then(resultData => {
            if (resultData && resultData.isSuccess) {
                console.log("Retrived issues successfully.");
                setdata(resultData.data.result);
            }
            else {
                console.log(resultData.message)
            }
        })
            .catch(error => {
                console.log(error)
            });
    }

    const restoreIssue = () => {
        const selecteddata: LegislativeIssues[] = gridApi?.getSelectedRows();
        const issueIds = selecteddata.map(it => it.id);
        const restoreIssue: RestoreIssues = { ids: issueIds, restoredBy: userinfo.account?.username, restoredDate: new Date(new Date().toISOString()) }
        RecoverDeletedIssues(restoreIssue, userinfo.accessToken).then(response =>
            response.json()
        ).then(resultData => {
            if (resultData && resultData.isSuccess) {
                console.log("Retrived issues successfully.");
                //setdata(resultData.data.result);
                setdata(data.filter(it => !issueIds.includes(it.id)));
            }
            else {
                console.log(resultData.message)
            }
        })
            .catch(error => {
                console.log(error)
            });
    }

    const valueGetters = (params) => {
        // return JSON.stringify(params.data[params.colDef.field].map(it => it.name).join(', '));
        return params.data[params.colDef.field]?.map(it => it.name).join(', ');
    };

    const [columndefs] = useState([
        // { field: "id",headerName:'AIM ID',width:'100'},
        { field: "shortSubject", headerName: 'Subject', tooltipField: 'shortSubject',flex:2, width: 350, checkboxSelection: true, headerCheckboxSelection: true },
        { field: "deletedBy", headerName: 'Deleted By', filter: "agTextColumnFilter", flex: 0.84 },
        { field: 'deletedDate', headerName: 'Deleted Date', width: 100, filter: "agTextColumnFilter", flex: 0.6, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        { field: 'region.name', headerName: 'Region', width: 100, filter: false, flex: 0.5 },
        { field: "jurisdictions", headerName: 'Jurisdiction', filter: "agTextColumnFilter", flex: 0.8, valueGetter: params => valueGetters(params) },
        { field: 'status.name', headerName: 'Status', width: 100, filter: "agTextColumnFilter", flex: 0.49 },
        { field: 'enforcementDate', headerName: 'Enforcement Date', headerClass: 'textcenter', filter: false, flex: 0.6, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        { field: 'action.name', headerName: 'Action', filter: "agTextColumnFilter", flex: 0.8 }
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true
        , filter: true
        , wrapHeaderText: true
        // ,floatingFilter:true
        // ,resizable:true
    }), []);


    const rowHeight = 24;

    const perPage = 15;
    const [gridApi, setGridApi] = useState<GridApi>();

    const OnGridReady = params => {
        setGridApi(params.api);
        // getIssues();
    }

    return <>
        <Row>
            <Col><Button className="mybtn btnfitright font12" onClick={restoreIssue}>Recover Issues</Button></Col>
        </Row>
        <Row className="overflowauto" style={{ height: 485, paddingTop: 0, overflowY: "hidden" }}>
            <AgGridReact className="ag-theme-alpine mygrid"
                // domLayout={'autoHeight'}
                defaultColDef={defaultColDef}
                rowData={data}
                animateRows={true}
                // cacheBlockSize={10}
                paginationPageSize={perPage}
                // onPaginationChanged={it => { setPageNumber(it.api.paginationGetCurrentPage() + 1); setPageSize(it.api.paginationGetPageSize()) }}
                pagination={true}
                rowHeight={rowHeight}
                headerHeight={38}
                enableBrowserTooltips={true}
                columnDefs={columndefs}
                onGridReady={OnGridReady}
                rowSelection={'multiple'}
            />



        </Row>
    </>
}
