
import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/585bda71-88ce-428b-9832-95eaa3dce989/',
        clientId: process.env.REACT_APP_ClientId,
        redirectUri: '/',
        postLogoutRedirectUri:process.env.REACT_APP_PostLogoutRedirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    // scopes: ["User.Read"]
    scopes: [process.env.REACT_APP_AppScope,"User.Read"]
};

// export const functionAppScopeRequest = {
//     scopes: [process.env.REACT_APP_AZFUNScope,"User.Read"]
// };

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphRoleEndpoint:"https://graph.windows.net/"
};
