import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import "./assets/css/style.css";

//Datatable Modules
import $ from 'jquery';
import "datatables.net/js/jquery.dataTables.js";
// import {DataTable} from "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
// import "datatables.net-bs5/js/dataTables.bootstrap5.min.js"

const msalinstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalinstance}>
    <App />
    </MsalProvider>
  </React.StrictMode>
);

// reportWebVitals(console.log);
