import React, { useContext, useEffect, useState } from "react";
import { Col, Button, Form, Container, Row, FloatingLabel } from "react-bootstrap";
import { TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from "@mui/material";
import DataContext from "../Contexts/DataContext.js";
import { styled } from '@mui/material/styles';
// import "../assets/css/style.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Category, Country, IssueAction, Region,IssueStatus,Keyword, TypeofEmergingIssue, BusinessUnit } from "../Types/DropDownModels.js";
import { getAllCategories, getCountries, getRegions, getIssueStatus, getAllActions, getAllKeyWords, GetJurisdictions, getAllTypeofEmergingIssues, GetBusinessUnits } from "../Services/GetDropDownsData.ts";
import UserContext from "../Contexts/UserContext.ts";
import IssueFilter from "../Types/IssueFilter.ts";
import Jurisdiction from "../Types/Jurisdiction.js";



export function IssueSearchFilter({ formdata, onChangeHandeler, getdata,resetFilter }) {
  const formData: IssueFilter = formdata;
  const [showFilter, setShowFilter] = useState(false);
  const userinfo = useContext(UserContext);
  const [regions, setregions] = useState<Region[]>([]);
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([]);
  const [TypeOfIssues, setTypeofIssue] = useState<TypeofEmergingIssue[]>([]);
  const [issueactions, setissueactions] = useState<IssueAction[]>([]);
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [keywords, setkeywords] = useState<Keyword[]>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {      
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    getRegions(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setregions(resultData.data.result.sort((a, b) => {
            if (a.order !== b.order) {
              return a.order - b.order;
            } else {
              return a.name.localeCompare(b.name);
            }
          }));
        }
      })
      .catch(error => console.log(error));

    GetJurisdictions(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setJurisdictions(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));

      getAllTypeofEmergingIssues(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setTypeofIssue(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));
    getAllActions(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setissueactions(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));

    GetBusinessUnits(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setBusinessUnits(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));

    getAllKeyWords(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setkeywords(resultData.data.result.sort((a, b) => {
    if (a.order !== b.order) {
    return a.order - b.order;
      } else {
    return a.name.localeCompare(b.name);
      }
    }));
        }
      })
      .catch(error => console.log(error));
  }, []);


  const handelmultiselect=(e)=>{
    const str=e.target.value;
    formData.categoryIds = typeof str ==='string'? str.split(',').map(it=>parseInt(it)):str;
  } 
  const [enforcementFromOpen, setEnforcementFromOpen] = useState(false);

  const [enforcementToOpen, setEnforcementToOpen] = useState(false);

  return <>
    <Form className="tocenter row issuesearchform"  style={{padding:0}}>
      <Col >
        <FormControl fullWidth>
          <TextField
            value={formData.subject}
            label="Subject"
            name="subject"
            onChange={onChangeHandeler}
            id="outlined-size-small"
            size="small"
          />
        </FormControl>
      </Col>
      <Col xs={2} style={{width:'auto', paddingRight:20}}>
        <Row>
        <Col style={{alignSelf:'center'}}>
        <Button className="font12" onClick={() => { getdata(formData); }}>Search</Button>
        </Col>
        <Col style={{textAlign:'center'}}>
        <Row>
        <Button onClick={() => setShowFilter(!showFilter)} className="font12" style={{ fontSize: 10,margin:10 }}>Advanced Search</Button>
        </Row>
        <Row>
        <Button onClick={()=>{resetFilter(); getdata(formData);}} className="font12" style={{ fontSize: 10,margin:10 }}>Reset</Button>
        </Row>
        </Col>
        </Row>
      </Col>
      <Col xs={8} id="adfilter">
        {showFilter ?
          <>
            <Row>
              <Col xs="3">
                {/* <TextField
                  value={formData.regionId}
                  label="Region"
                  name="regionId"
                  onChange={onChangeHandeler}
                  id="outlined-size-small"
                  defaultValue="Small"
                  size="small"
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="outlined-size-small-label">Region</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    id="outlined-size-small"
                    value={formData.regionId}
                    label="Region"
                    name="regionId"
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={onChangeHandeler}
                  >
                    {
                      regions.map((option) => {
                        return <MenuItem value={option.regionId}>{option.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
              <Col xs="3">
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="outlined-size-small-label" className="labelbgwhite">Jurisdiction</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    multiple
                    id="fontsizesmall"
                    name="jurisdictionIds"
                    fullWidth
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    value={formData.jurisdictionIds}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={() => jurisdictions.filter(it => formData.jurisdictionIds && formData.jurisdictionIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                    onChange={onChangeHandeler}
                    MenuProps={MenuProps}
                  >
                    {jurisdictions.map((option) => (
                      <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                        <Checkbox checked={formData.jurisdictionIds && formData.jurisdictionIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xs="3">
                <FormControl fullWidth>
                  <InputLabel id="outlined-size-small-label">Business Unit</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    id="outlined-size-small"
                    value={formData.businessUnitId}
                    label="Business Unit"
                    name="businessUnitId"
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={onChangeHandeler}
                  >
                    {
                      businessUnits.map((option) => {
                        return <MenuItem value={option.id}>{option.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
              <Col xs="3">
                <FormControl fullWidth id="formControl">
                  <InputLabel id="outlined-size-small-label" className="labelbgwhite">Keyword</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    multiple
                    name="keywordIds"
                    id="outlined-size-small"
                    value={formData.keywordIds}
                    onChange={onChangeHandeler}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => keywords.filter(it => formData.keywordIds && formData.keywordIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {keywords.map((option) => (
                      <MenuItem key={option.id} value={option.id} style={{padding:0,paddingLeft:16}}>
                        <Checkbox checked={formData.keywordIds && formData.keywordIds.indexOf(option.id) > -1} style={{padding:6}}/>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <FormControl id="searchdate" fullWidth>
                  <DatePicker
                    label="Enforcement From"
                    open={enforcementFromOpen}
                    onOpen={() => setEnforcementFromOpen(true)}
                    onClose={() => setEnforcementFromOpen(false)}
                    value={formData.enforcementFromDate?dayjs(formData.enforcementFromDate):null}
                    onChange={(date) => { formData.enforcementFromDate = date && date.toISOString() }}
                    format="ll"
                    // inputProps={{ MenuProps: { disableScrollLock: true } }}
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        id: "enforcementId",
                        onKeyDown: (e) => e.preventDefault(),
                        onClick: (e) => { setEnforcementFromOpen(true) }
                      }
                    }}
                  />
                </FormControl>
              </Col>
              <Col xs="3">
                <FormControl id="searchdate" fullWidth>
                <DatePicker
                    label="Enforcement To"
                    open={enforcementToOpen}
                    onOpen={() => setEnforcementToOpen(true)}
                    onClose={() => setEnforcementToOpen(false)}
                    value={formData.enforcementToDate?dayjs(formData.enforcementToDate):null}
                    onChange={(date) => { formData.enforcementToDate = date && date.toISOString() }}
                    format="ll"
                    sx={{ width: '100%' }}
                    // inputProps={{ MenuProps: { disableScrollLock: true } }}
                    slotProps={{
                      textField: {
                        id: "enforcementId",
                        onKeyDown: (e) => e.preventDefault(),
                        onClick: (e) => { setEnforcementToOpen(true) }
                      }
                    }}
                  />                </FormControl>
              </Col>
              <Col xs="3">
                <FormControl fullWidth>
                  <InputLabel id="outlined-size-small-label">Action</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    id="outlined-size-small"
                    value={formData.actionId}
                    label="Action"
                    name="actionId"
                    onChange={onChangeHandeler}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    MenuProps={MenuProps}
                  >
                    {
                      issueactions.map((option) => {
                        return <MenuItem value={option.id}>{option.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
              <Col xs="3">
              <FormControl fullWidth>
                  <InputLabel id="outlined-size-small-label">Type Of Issue</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    id="outlined-size-small"
                    value={formData.TypeOfIssueId}
                    label="TypeOfIssueId"
                    name="TypeOfIssueId"
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={onChangeHandeler}
                  >
                    {
                      TypeOfIssues.map((option) => {
                        return <MenuItem value={option.id}>{option.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </>
          : ''
        }
      </Col>
    </Form>
  </>
}