import { AgGridReact } from "ag-grid-react";

import React, { useContext, useEffect, useState } from "react";
import CellRenderer from "./cellRenderer.tsx";
import { ISelectCellEditorParams } from "ag-grid-community";
import UserContext from "../Contexts/UserContext.ts";
import { AddDDL, DeleteDDL, UpdateDDL } from "../Services/AdminService.ts";
import BasicData from "../Types/BasicData.ts";
import WarningDialog from "./Warningdialog.jsx";
import { Row } from "react-bootstrap";


export default function ({ options, controllerName, setoptions, gridref, setIsEdit }) {
    const confirmMessage = <p>This is permanent <span style={{ color: 'red', fontWeight: "bold" }}>Delete</span>, Do You want to proceed?</p>;
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const openDeleteConfirmation = () => { setIsConfirmDelete(true) }
    const closeDeleteConfirmation = () => { setIsConfirmDelete(false) }
    const [idToDelete, setIdToDelete] = useState();
    const userinfo = useContext(UserContext);


    
    const editingStarted = () => {
        setIsEdit(true);
    }

    const editingStopped = () => {
        setIsEdit(false);
    }

    const deletItem = () => {
        DeleteDDL(controllerName, options.find(it => it.id === idToDelete), userinfo.accessToken)
            .then(responce => responce.json())
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    // dataContext.issuesList = resultData.data.result;
                    setoptions((options as BasicData[]).filter(it => it.id !== idToDelete));
                    console.log("DDL deleted successfully.");
                }
                else {
                    console.info(resultData.message)
                }
            })
            .catch(error => console.error(error));
    }

    const update = (data: BasicData | undefined) => {
        if (!data) {
            console.error("data is empty", data);
            return;
        };
        data.updatedBy = userinfo.account?.username;
        data.updatedDate = new Date();
        UpdateDDL(controllerName, data, userinfo.accessToken)
            .then(responce => responce.json())
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    console.log("DDL update successfully.");
                  
                        const data = options && options.filter(it => it != null && it.name != null && it.name != '').sort((a, b) => {
                          if (a.order !== b.order) {
                            return a.order - b.order;
                          } else {
                            return a.name.localeCompare(b.name);
                          }
                        });
                        setoptions(data);
                    
                }
                else {
                    console.info(resultData.message)
                }

            })
            .catch(error => console.error(error));
    };


    const saveDDL = (data: BasicData | undefined) => {
        if (!data) {
            console.error("data is empty", data);
            return;
        }

        data.createdBy = userinfo.account?.username;
        data.createdDate = new Date();

        AddDDL(controllerName, data, userinfo.accessToken)
            .then(responce => responce.json())
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setoptions(data=>[...data, resultData.data]);
                    
                    setoptions(data=> data?.sort((a, b) => {
                        if (a.order !== b.order) {
                          return a.order - b.order;
                        } else {
                          return a.name.localeCompare(b.name);
                        }
                      }));

                    console.log("DDL saved successfully.", options);
                }
                else {
                    console.info(resultData.message)
                }

            })
            .catch(error => console.error(error));

    };


    const [columnDefs, setcolumnDefs] = useState([
        { field: 'name', flex: 1,floatingFilterComponentParams: { suppressFilterButton: true } },

        {
            field: 'order',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 0,
            },
            flex: 1,
            floatingFilterComponentParams: { suppressFilterButton: true }
        },

        {
            field: 'isActive', headerName: "Active",
            cellEditor: 'agSelectCellEditor',
            flex: 1,
            cellEditorParams: {
                values: [true, false],
            } as ISelectCellEditorParams,
            floatingFilterComponentParams: { suppressFilterButton: true }
        },

        {
            headerName: '',
            colId: 'actions',
            cellRenderer: (props) => CellRenderer(props, deleteConfirm, update, saveDDL, editingStarted, editingStopped),
            editable: false,
            filter: false,
            width: 140,
        }
    ]);

    const deleteConfirm = (data) => {
        if (data.id) {
            setIdToDelete(data.id);
            openDeleteConfirmation();
        }
        else {
            gridref.current.applyTransaction({ remove: [{}] });
        }
    }

    const defaultColDef = {
        editable: true,
        resizable: false,
        filter: true,
        sortable: true,
        floatingFilter: true,
        suppressKeyboardEvent: () => { return true }
    };

    useEffect(() => {
        setcolumnDefs(
            [
                { field: 'name', flex: 1 ,floatingFilterComponentParams: { suppressFilterButton: true }},

                {
                    field: 'order',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        precision: 0,
                    },
                    flex: 1,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                },

                {
                    field: 'isActive', headerName: "Active",
                    cellEditor: 'agSelectCellEditor',
                    flex: 1,
                    cellEditorParams: {
                        values: [true, false],
                    } as ISelectCellEditorParams,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                },

                {
                    headerName: '',
                    colId: 'actions',
                    cellRenderer: (props) => CellRenderer(props, deleteConfirm, update, saveDDL, editingStarted, editingStopped),
                    editable: false,
                    filter: false,
                    width: 140,
                }
            ]
        );
    }, [controllerName,options]);

    return (

        <>
            <Row className="overflowauto"  style={{ padding: 30, paddingTop: 0, overflowY: "hidden" }}>
                <div className="ag-theme-alpine" style={{ height: "100%" }}>
                    <AgGridReact
                        className="ddlgrid mygrid"
                        columnDefs={columnDefs}
                        ref={gridref}
                        defaultColDef={defaultColDef}
                        rowData={options}
                        enableCellEditingOnBackspace={false}
                        suppressClickEdit={true}
                        // onRowEditingStopped={(param) => { update(param.data); }}
                        pagination={true}
                        paginationPageSize={10}
                        stopEditingWhenCellsLoseFocus={false}
                        // paginationAutoPageSize={true}
                        domLayout={'autoHeight'}
                        editType="fullRow">
                    </AgGridReact>
                </div>
            </Row>

            <WarningDialog opendeleteconfirmation={openDeleteConfirmation} closedeleteconfirmation={closeDeleteConfirmation} isdeleteconfirm={isConfirmDelete} deleteitem={deletItem} message={confirmMessage} />

        </>

    );
}