import workGroupFilter from "../Types/WorkGroupFilter.ts"
import { Workinggroup } from "../Types/Workinggroup.js";


const urlendpoint=process.env.REACT_APP_Urlendpoint;

export function getWorkingGroups(token): any {

    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}WorkingGroups/GetIssues`, options)

}

export function getById(id: string, token: string): any {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
  return  fetch(`${urlendpoint}WorkingGroups/GetByIdAsync/${id}`, options);
}

export function addAsync(data: Workinggroup, token: string): any {
    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "POST",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}WorkingGroups/AddAsync`, options);
}

// export function updateIssue(data: WorkingGroupPost, token: string): any {
//     var headers = new Headers();
//     const bearer = `Bearer ${token}`;
//     headers.append("Authorization", bearer);
//     headers.append('Accept', 'application/json, text/plain');
//     headers.append('Content-Type', 'application/json;charset=UTF-8');
//     const options = {
//         method: "PUT",
//         headers: headers,
//         ContentType: 'application/json',
//         body: JSON.stringify(data)
//     };
//     return fetch(`${urlendpoint}WorkingGroups/UpdateIssue`, options);
// }

export function GetFilteredData(data: workGroupFilter, token: string): any {

    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "POST",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}WorkingGroups/GetFilteredData`, options);
}

export function UpdateWorkingGroup(data: Workinggroup, token: string): any {

    var headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "PUT",
        headers: headers,
        ContentType: 'application/json',
        body: JSON.stringify(data)
    };
    return fetch(`${urlendpoint}WorkingGroups/UpdateWorkGroup`, options);
}

export function DeleteWorkingGroup(id:number,token: string):any{
    //https://localhost:7267/api/LegislativeIssue/DeleteIssue?id=0
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "Delete",
        headers: headers
    };
  return  fetch(`${urlendpoint}WorkingGroups/DeleteAsync/${id}`, options);
}