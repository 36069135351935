import React from "react";
import { NoAccess } from "../Pages/NoAccess";

export function RoleBasedAccess(props) {

    const userRoles: string[] = props.userroles;
    const roleReq: string[] = props.rolereq;

    const ValidateRole = () => {
        // console.log("roles",userRoles);
       return  userRoles.some(it => roleReq.includes(it))
    };



    return <>{ValidateRole() ? <>{props.children}</> : <NoAccess></NoAccess>}</>

}