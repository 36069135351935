
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Select, Skeleton, Switch } from "@mui/material";
import LegislativeIssues from "../Types/LegislativeIssues";
import Textarea from "@mui/joy/Textarea";
import Send from "@mui/icons-material/Send"
import { Edit } from "@mui/icons-material";
import { GetBusinessUnits, GetJurisdictions, getAllActions, getAllCategories, getAllCustomers, getAllImpactServiceLine, getAllKeyWords, getAllLegislativeStatuses, getAllTypeofEmergingIssues, getCountries, getIssueStatus, getRegions } from "../Services/GetDropDownsData.ts";
import { BusinessUnit, Category, Country, CustomerParticipation, ImpactForServiceLine, IssueAction, IssueStatus, Keyword, LegislativeStatus, Region, TypeofEmergingIssue } from "../Types/DropDownModels";
import CustomerIssueDetail from "../Types/CustomerIssueDetail";
import Customer from "../Types/Customer";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import ActionDetail from "../Types/ActionDetail.ts";
import { DeleteIssue } from "../Services/ApiServices.ts";
import { Response } from "../Types/Response.ts";
import WarningDialog from "../Components/Warningdialog";
import Jurisdiction from "../Types/Jurisdiction.ts";
import { Role } from "../Types/Role.ts";



export default function IssuesForm({ initValues, userinfo, submitData, goTo, isEdit = false, isReadonly = false }) {
    const datenow = () => dayjs(Date.now()).toDate() //dayjs(Date.now()).format("DD/MM/YYYY");

    const initialValues:LegislativeIssues = initValues;
    const [actiondata, setactiondata] = useState('');

    const [regions, setregions] = useState<Region[]>([]);
    const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([]);
    const [categories, setcategories] = useState<Category[]>([]);
    const [custParticipations, setcustParticipations] = useState<CustomerParticipation[]>([]);
    const [impactServiceLines, setimpactServiceLines] = useState<ImpactForServiceLine[]>([]);
    const [issueactions, setissueactions] = useState<IssueAction[]>([]);
    const [issueStatuses, setissueStatuses] = useState<IssueStatus[]>([]);
    const [keywords, setkeywords] = useState<Keyword[]>([]);
    const [legislativeStatuses, setlegislativeStatuses] = useState<LegislativeStatus[]>([]);
    const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
    const [typeofEmergingIssues, settypeofEmergingIssues] = useState<TypeofEmergingIssue[]>([]);
    const [custId, setcustId] = useState('');
    const [customers, setcustomers] = useState<Customer[]>([]);
    const [editActionDetail, setEditActionDetail] = useState<ActionDetail>();
    const [enforcementOpen, setEnforcementOpen] = useState(false);
    const [DueOpen, setDueOpen] = useState(false);
    const [isActionEdit, setActionEditPop] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);



    useEffect(() => {
        getRegions(userinfo?.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setregions(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        GetJurisdictions(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setJurisdictions(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllCategories(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setcategories(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllImpactServiceLine(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setimpactServiceLines(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllActions(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setissueactions(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getIssueStatus(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setissueStatuses(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

            GetBusinessUnits(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setBusinessUnits(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllKeyWords(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setkeywords(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllLegislativeStatuses(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setlegislativeStatuses(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));


        getAllTypeofEmergingIssues(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    settypeofEmergingIssues(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

        getAllCustomers(userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    setcustomers(resultData.data.result.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }));
                }
            })
            .catch(error => console.log(error));

    }, []);


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const validationSchema = Yup.object<LegislativeIssues>().shape({
        // aimId: Yup.number().required("Required"),
        createdDate: Yup.string().default(new Date().toISOString()).required("Required"),
        // UpdatedDate: Yup.date().required("Required"),
        // typeofemergingissue: Yup.string().required("Typeofemergingissue is Required"),
        emergingIssueId: Yup.number().required().min(1),
        regionId: Yup.number().required().min(1),
        jurisdictionIds: Yup.array().required().min(1),
        state_Province: Yup.string().notRequired(),
        county_Districts: Yup.string().notRequired(),
        city_Municipalities: Yup.string().notRequired(),
        keywordIds: Yup.array().required().min(1),
        subject: Yup.string().required(),
        // actionDetails: [],
        enforcementDetails: Yup.string(),
        enforcementDate: Yup.string().nullable().default(null),
        actionId: Yup.number().required().min(1),
        categoryIds: Yup.array().required().min(1),
        impactforServiceLineIds: Yup.array().required().min(1),
        url: Yup.string().notRequired(),
        additionalUrl:Yup.string().notRequired(),
        businessUnitId:Yup.number().required().min(1),
        statusId: Yup.number().required().min(1),
        opentoCustomer: Yup.boolean().required(),
        createdBy: Yup.string(),
        dueDate: Yup.string().nullable().default(null),
        legislativeStatusId: Yup.number().required().min(1)
        // customerParticipationId: Yup.number().required().min(1)
    });

    const enterActionDetails = (values: LegislativeIssues) => {
        const uniqueId = Date.now() * Math.random();

        if (!custId || !actiondata) {
            console.log(`${!custId ? "cust id not there" : "actiondatanotthere"}`);
            return;
        }
        const issueactionDetails = values.actionDetails?.find(it => it.customerId === custId);
        const data: ActionDetail = {
            action: actiondata, createdDate: datenow(), createdBy: userinfo.account?.username ?? '', serialNumber: uniqueId, updatedDate: null, updatedBy: null
        }
        if (issueactionDetails) {
            issueactionDetails.customerId = custId;
            if (issueactionDetails.actionDetails) {
                issueactionDetails.actionDetails.push(data);
            }
            else {
                issueactionDetails.actionDetails = [data]
            }
        }
        else {
            const CustIssueDetail: CustomerIssueDetail = {
                customerId: custId,
                actionDetails: [data]
            };
            values.actionDetails?.push(CustIssueDetail);
        }
        setactiondata('');
    }

    const updateActionDetail = (values: LegislativeIssues) => {
        if (editActionDetail === undefined) {
            return;
        }
        const customerIndex = values.actionDetails?.findIndex(c => c.customerId === custId);

        const matchingSerialNumber = values.actionDetails[customerIndex].actionDetails.findIndex(ele => editActionDetail.id ? ele.id === editActionDetail?.id : ele.serialNumber === editActionDetail?.serialNumber);

        values.actionDetails[customerIndex].actionDetails[matchingSerialNumber] = {

            ...values.actionDetails[customerIndex].actionDetails[matchingSerialNumber],
            action: editActionDetail.action, updatedDate: editActionDetail.id ? datenow() : null, updatedBy: editActionDetail.id ? userinfo.account?.username : null
            // serialNumber: editActionDetail?.serialNumber
        }
        handleCloseEditAction();
    }

    const handleOpenEditAction = () => {
        setActionEditPop(true);
    };
    const handleCloseEditAction = () => {
        setActionEditPop(false);
    };

    const openDeleteConfirmation = () => { setIsConfirmDelete(true) }

    const closeDeleteConfirmation = () => { setIsConfirmDelete(false) }
    const logsvalues = (values, error) => {
        console.log(values, error);
    }
    const deleteissue = () => {
        if (!isEdit) {
            return;
        }
        DeleteIssue(initValues.id, userinfo.accessToken)
            .then(response => response.json())
            .then((result: Response<LegislativeIssues>) => {
                if (result.isSuccess) {
                    console.log("Issue deleted sucessfully.");
                    goTo();
                }
                else {
                    // enqueueSnackbar("Issue deteting failed.", {variant:"error"});
                    console.log(result.message);
                }
            })
            .catch(error => {
                // enqueueSnackbar("Issue delete failed!",{variant:"error"});
                console.log(error);
            })
        // if(success){
        //     enqueueSnackbar("Issue deleted sucessfully.", {variant:"success"});
        // }
    }


    return (<>
        {/* <h1 className="pageHeading">Add Issue Form</h1> */}
        <div className="boxborder" style={{ margin: 15 }}>
            {initialValues ?
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => { submitData(values); }}
                >
                    {({ values, errors, touched, isValid, dirty, setValues, handleChange, handleBlur }) => (
                        <>
                            <Form id="issueform" className="row" style={{ paddingRight: 15, paddingLeft: 15 }}>
                                {/* Col one for left side of page */}
                                <Col xs="6">
                                    {/* type of emerging issue/Category */}
                                    <Row>
                                        <Col xs="6">
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="emergingIssueId"
                                                select
                                                value={values.emergingIssueId}
                                                id="fontsizesmall"
                                                label="Type Of Issues"
                                                error={errors.emergingIssueId && touched.emergingIssueId}
                                                helperText={errors.emergingIssueId && touched.emergingIssueId}
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {typeofEmergingIssues.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col xs="6">
                                            <FormControl sx={{ width: '100%' }} error={(touched.categoryIds && errors.categoryIds !== undefined) ? true : false}>
                                                <InputLabel id="category-label" className="labelbgwhite font12">Category</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="category-label"
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    multiple
                                                    id="fontsizesmall"
                                                    name="categoryIds"
                                                    value={values.categoryIds}
                                                    onChange={handleChange}
                                                    disabled={isReadonly}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={(selected) => values.categoryIds.length > 0 ? categories.filter(it => values.categoryIds.indexOf(it.id) > -1).map(it => it.name).join(', ') : " l"}
                                                    MenuProps={MenuProps}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                >
                                                    {categories.map((option) => (
                                                        <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                                                            <Checkbox checked={values.categoryIds && values.categoryIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    {/*Subject  */}
                                    <Row>
                                        <Col>
                                            <FormControl sx={{ width: '100%' }}>
                                                <label className="font12" htmlFor="subject-id">Subject</label>
                                                <Textarea
                                                    color={touched.subject && errors.subject !== undefined ? "danger" : "neutral"}
                                                    maxRows={4}
                                                    minRows={4}
                                                    onBlur={handleBlur}
                                                    value={values.subject}
                                                    onChange={handleChange}
                                                    size="sm"
                                                    name="subject"
                                                    placeholder="Subject"
                                                    variant="soft"
                                                    slotProps={{
                                                        textarea: {
                                                            id: 'subject-id',
                                                        }
                                                    }}
                                                    readOnly={isReadonly}
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    {/* enforcementdate / Enforcement Field*/}
                                    <Row>
                                        <Col>
                                            <FormControl sx={{ width: '100%' }}>
                                                <DatePicker
                                                    label="Enforcement Date"
                                                    open={enforcementOpen}
                                                    onOpen={() => setEnforcementOpen(true)}
                                                    onClose={() => setEnforcementOpen(false)}
                                                    value={values.enforcementDate ? dayjs(values.enforcementDate) : null}
                                                    onChange={(date) => { values.enforcementDate = date && date.toISOString() }}
                                                    format="LL"
                                                    sx={{ width: '100%' }}
                                                    slotProps={{
                                                        textField: {
                                                            id: "enforcementId",
                                                            onKeyDown: (e) => e.preventDefault(),
                                                            onClick: (e) => { setEnforcementOpen(true) }
                                                        }
                                                    }}
                                                    disabled={isReadonly}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col>
                                            <TextField
                                                id="enforeDetailId"
                                                value={values.enforcementDetails || ""}
                                                onChange={handleChange}
                                                name="enforcementDetails"
                                                label="Enforcement Detail"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                    </Row>
                                    {/* DueDate /Status  */}
                                    <Row >
                                        <Col>
                                            <FormControl sx={{ width: '100%' }} >
                                                <DatePicker
                                                    label="Due Date"
                                                    open={DueOpen}
                                                    onOpen={() => setDueOpen(true)}
                                                    onClose={() => setDueOpen(false)}
                                                    value={values.dueDate ? dayjs(values.dueDate) : null}
                                                    onChange={(date) => { values.dueDate = date && date.toISOString() }}
                                                    format="LL"
                                                    sx={{ width: '100%' }}
                                                    slotProps={{
                                                        textField: {
                                                            id: "duedateId",
                                                            onKeyDown: (e) => e.preventDefault(),
                                                            onClick: (e) => { setDueOpen(true) }
                                                        }
                                                    }}
                                                    disabled={isReadonly}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="statusId"
                                                select
                                                label="Status"
                                                error={errors.statusId && touched.statusId}
                                                helperText={errors.statusId && touched.statusId}
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                id="fontsizesmall"
                                                disabled={isReadonly}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {issueStatuses.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                    </Row>
                                    {/* Region & Country*/}
                                    <Row>
                                        <Col xs="6">
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="regionId"
                                                label="Region"
                                                error={errors.regionId && touched.regionId}
                                                helperText={errors.regionId && touched.regionId}
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                select
                                                id="fontsizesmall"
                                                disabled={isReadonly}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {regions.map((option) => (
                                                    <MenuItem key={option.regionId} value={option.regionId}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col xs="6">
                                            <FormControl sx={{ width: '100%' }} error={(touched.jurisdictionIds && errors.jurisdictionIds !== undefined) ? true : false}>
                                                <InputLabel id="jurisdiction-label" className="labelbgwhite font12">Jurisdiction</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="jurisdiction-label"
                                                    multiple
                                                    id="fontsizesmall"
                                                    name="jurisdictionIds"
                                                    fullWidth
                                                    value={values.jurisdictionIds}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={() => jurisdictions.filter(it => values.jurisdictionIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    disabled={isReadonly}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                >
                                                    {jurisdictions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                                                            <Checkbox checked={values.jurisdictionIds && values.jurisdictionIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    {/* Country District/ URL  */}
                                    <Row>
                                        <Col>
                                            {/* <Field
                                                size="small"
                                                as={TextField}
                                                name="county_Districts"
                                                label="County/Districts"
                                                value={values.state_Province || ''}
                                                onChange={handleChange}
                                                error={errors.county_Districts && touched.county_Districts}
                                                helperText={errors.county_Districts && touched.county_Districts}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                                            /> */}
                                            <TextField
                                                id="county_Districtsid"
                                                value={values.county_Districts || ""}
                                                onChange={handleChange}
                                                name="county_Districts"
                                                label="County/Districts"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                        <Col>
                                            {/* <Field
                                                size="small"
                                                as={TextField}
                                                name="url"
                                                label="URL"
                                                value={values.url ?? ""}
                                                onChange={handleChange}
                                                error={errors.url && touched.url}
                                                helperText={errors.url && touched.url}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                                            /> */}
                                             
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="businessUnitId"
                                                select
                                                label="Business Unit"
                                                error={errors.businessUnitId && touched.businessUnitId}
                                                helperText={errors.businessUnitId && touched.businessUnitId}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {businessUnits.map((option) => (
                                                    <MenuItem key={option.id} aria-selected={businessUnits[0].id==option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        
                                    </Row>
                                    {/* State Province/City Municpalities*/}
                                    <Row>
                                        <Col>
                                            {/* <Field
                                                size="small"
                                                as={TextField}
                                                id="fontsizesmall"
                                                name="state_Province"
                                                label="State/Province "
                                                value={values.state_Province??""}
                                                onChange={handleChange}
                                                error={errors.state_Province && touched.state_Province}
                                                helperText={errors.state_Province && touched.state_Province}
                                                fullWidth
                                            /> */}
                                            <TextField
                                                id="state_Provinceid"
                                                value={values.state_Province || ""}
                                                onChange={handleChange}
                                                name="state_Province"
                                                label="State/Province"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                        <Col>
                                            {/* <Field
                                                size="small"
                                                as={TextField}
                                                name="city_Municipalities"
                                                label="City/Municipalities"
                                                value={values.city_Municipalities??""}
                                                onChange={handleChange}
                                                error={errors.city_Municipalities && touched.city_Municipalities}
                                                helperText={errors.city_Municipalities && touched.city_Municipalities}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                                            /> */}

                                            <TextField
                                                id="city_Municipalitiesid"
                                                value={values.city_Municipalities || ""}
                                                onChange={handleChange}
                                                name="city_Municipalities"
                                                label="City/Municipalities"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col>
                                            <TextField
                                                id="urlid"
                                                value={values.url || ""}
                                                onChange={handleChange}
                                                name="url"
                                                label="URL"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                id="alturlid"
                                                value={values.additionalURL || ""}
                                                onChange={handleChange}
                                                name="additionalURL"
                                                label="Additional URL"
                                                sx={{ width: '100%' }}
                                                disabled={isReadonly}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                {/* End of left side column */}
                                {/* Col second for right hand side of page */}
                                <Col xs="6">
                                    <Row>
                                        <Col xs="6">
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="legislativeStatusId"
                                                select
                                                label="Legislative Status"
                                                error={errors.legislativeStatusId && touched.legislativeStatusId}
                                                helperText={errors.legislativeStatusId && touched.legislativeStatusId}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    disabled={isReadonly}
                                            >
                                                {legislativeStatuses.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col xs="6">
                                            <FormControl sx={{ width: '100%' }} error={(touched.keywordIds && errors.keywordIds !== undefined) ? true : false}>
                                                <InputLabel id="keywords-label"  className="labelbgwhite font12">Keywords</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="keyword-label"
                                                    multiple
                                                    id="fontsizesmall"
                                                    name="keywordIds"
                                                    fullWidth
                                                    value={values.keywordIds}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={() => keywords.filter(it => values.keywordIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    disabled={isReadonly}
                                                >
                                                    {keywords.map((option) => (
                                                        <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                                                            <Checkbox checked={values.keywordIds && values.keywordIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    {/* Action & Impact of Service Line */}
                                    <Row>
                                        <Col xs="6">
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="actionId"
                                                select
                                                label="Action"
                                                error={errors.actionId && touched.actionId}
                                                helperText={errors.actionId && touched.actionId}
                                                fullWidth
                                                id="fontsizesmall"
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    disabled={isReadonly}
                                            >
                                                {issueactions.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col xs="6">
                                            <FormControl sx={{
                                                width: '100%'
                                            }} error={(touched.impactforServiceLineIds && errors.impactforServiceLineIds !== undefined) ? true : false}>
                                                <InputLabel id="impactforServiceLine-label"  className="labelbgwhite font12">Impact for Service Line</InputLabel>
                                                <Select
                                                    size="small"
                                                    id="fontsizesmall"
                                                    onBlur={handleBlur}
                                                    labelId="impactforServiceLine-label"
                                                    multiple
                                                    name="impactforServiceLineIds"
                                                    value={values.impactforServiceLineIds}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={(selected) => impactServiceLines.filter(it => values.impactforServiceLineIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                                                    MenuProps={MenuProps}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    disabled={isReadonly}
                                                >
                                                    {impactServiceLines.map((option) => (
                                                        <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                                                            <Checkbox checked={values.impactforServiceLineIds && values.impactforServiceLineIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Col>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                name="customerParticipationId"
                                                select
                                                label="Customer Participation"
                                                error={errors.customerParticipationId && touched.customerParticipationId}
                                                helperText={errors.customerParticipationId && touched.customerParticipationId}
                                                fullWidth
                                                id="fontsizesmall"
                                                sx={{ width: '100%' }}
                                            >
                                                {custParticipations.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col> */}
                                        <Col >
                                            <FormControlLabel id="openToCust" control={<Switch
                                                checked={values.opentoCustomer}
                                                defaultChecked
                                                id="fontsizesmall"
                                                name="opentoCustomer"
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                disabled={isReadonly}
                                            />} label="Open To Customer" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                select
                                                value={custId}
                                                label="Customer"
                                                name="custId"
                                                id="fontsizesmall"
                                                className="customer"
                                                onChange={(e) => { setcustId(e.target.value); }}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    fullWidth
                                            >
                                                {customers.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>
                                        <Col style={{ height: 40 }}>
                                            <FormControl variant="outlined" fullWidth sx={{ width: '100%' }} >
                                                <InputLabel id="actionDetailId-label" className="labelbgwhite" htmlFor="outlined-adornment-password">Action Details</InputLabel>
                                                <OutlinedInput
                                                    value={actiondata}
                                                    disabled={!custId || isReadonly}
                                                    onChange={(e) => { setactiondata(e.target.value) }}
                                                    id="actionDetailId"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                disabled={!custId || !actiondata}
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { enterActionDetails(values) }}
                                                                //   onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {/* <Send /> */}
                                                                <Button disabled={!custId || !actiondata} style={{ width: 'fit-content', fontSize: 12 }}>Enter</Button>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="actiondata"
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row style={{ padding: 15 }}>
                                        <List id="actiondetailslist" sx={{ width: '100%', bgcolor: 'background.paper' }} style={{ overflowY: 'auto', height: 194 }}>
                                            {(values as LegislativeIssues).actionDetails?.find(it => it.customerId === custId)?.actionDetails?.map((actionobj) => {
                                                return <>
                                                    <ListItem>
                                                       {!isReadonly ? <ListItemAvatar>
                                                            {/* <Avatar> */}
                                                            <Edit onClick={() => { setEditActionDetail(actionobj); handleOpenEditAction(); }} />
                                                            {/* </Avatar> */}
                                                        </ListItemAvatar> : null}
                                                        <ListItemText secondary={actionobj.updatedDate ? new Date(actionobj.updatedDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' }) : new Date(actionobj.createdDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })} primary={actionobj.action} />
                                                    </ListItem>
                                                </>
                                            })}
                                        </List>
                                    </Row>
                                    <Row>
                                        {/* <Col xs={6}></Col> */}
                                        <Col>

                                           {!isReadonly ? <Button size="sm" variant="success" className="mybtn btnfitright font12" type="submit" onClick={() => logsvalues(values, errors)} //disabled={!(dirty && isValid)}
                                            >Submit</Button> : null}
                                            <Button size="sm" variant="warning" className="mybtn btnfitright font12" onClick={goTo}>Cancel</Button>
                                            {(isEdit && !isReadonly) ? <Button size="sm" variant="danger" className="mybtn btnfitright font12" style={{ marginRight: 80 }} onClick={openDeleteConfirmation}>
                                                Delete</Button> : ''}
                                        </Col>
                                    </Row>
                                </Col>
                                <> {(isReadonly || isEdit) &&

                                    <p style={{ margin: 0 }}>Created by: {initialValues.createdBy + " " + new Date(initialValues.createdDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })} &nbsp;&nbsp; Last Updated by:  {(initialValues.updatedBy && initialValues.updatedDate) && initialValues.updatedBy + " " + new Date(initialValues.updatedDate).toLocaleDateString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })}</p>

                                }
                                </>
                                {/* End of Right Side Column */}
                            </Form>

                            <Dialog
                                onClose={handleCloseEditAction}
                                aria-labelledby="customized-dialog-title"
                                open={isActionEdit}
                                fullWidth={true}
                            >
                                <DialogTitle sx={{ m: 0, p: 2 }}>
                                    <h1 className="font12">Edit Action Details</h1>
                                    {handleCloseEditAction ? (
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleCloseEditAction}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    ) : null}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Textarea
                                        maxRows={4}
                                        minRows={4}
                                        value={editActionDetail?.action}
                                        name="action"
                                        onChange={(e) => { editActionDetail && setEditActionDetail(() => ({ ...editActionDetail, action: e.target.value })) }}
                                        size="sm"
                                        variant="soft"
                                        slotProps={{
                                            textarea: {
                                                className: 'font12',
                                                id: 'ActionDetail-id',
                                            }
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus className="font12" onClick={handleCloseEditAction}>
                                        Cancel changes
                                    </Button>
                                    <Button className="font12" autoFocus onClick={() => { updateActionDetail(values) }}>
                                        Save changes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                </Formik>
                :
                <Skeleton variant="rounded" animation="wave" width={'auto'} height={450} />
            }
        </div>

        <WarningDialog opendeleteconfirmation={openDeleteConfirmation} closedeleteconfirmation={closeDeleteConfirmation} isdeleteconfirm={isConfirmDelete} deleteitem={deleteissue} />
    </>
    );
};
