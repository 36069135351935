import React from "react";
import { AuthenticationResult } from "@azure/msal-browser";

const authresult:AuthenticationResult = {
    authority: '',
    uniqueId: '',
    tenantId: '',
    scopes: [],
    account: null,
    idToken: '',
    idTokenClaims:{roles:null},
    accessToken: '',
    fromCache: false,
    expiresOn:  null,
    tokenType: '',
    correlationId: '',
    requestId: '',
    extExpiresOn: new Date(),
    state: '',
    familyId: '',
    cloudGraphHostName: '',
    msGraphHost: '',
    code: '',
    fromNativeBroker: false
}; 
const UserContext = React.createContext<AuthenticationResult>(authresult);
export default UserContext;