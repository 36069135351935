import LegislativeIssues from "../Types/LegislativeIssues";
import IssueFilter from "../Types/IssueFilter";


const urlendpoint=process.env.REACT_APP_Urlendpoint;

export function getLegislativeIssues(token): any {

    const headers = new Headers();

    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`${urlendpoint}LegislativeIssue/GetIssues`, options)

}

export function GetBlobsPaging(pageNumber: number, pageSize: number,token :string): any {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
  return  fetch(`${urlendpoint}ExcelFile/GetBlobsPaging/${pageNumber}/${pageSize}`, options);
}

export function DownloadFileWithName(fileName: string, token: string): any {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json, text/plain');
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    const options = {
        method: "GET",
        headers: headers
    };
  return  fetch(`${urlendpoint}ExcelFile/DownloadFileWithName/${fileName}`, options);
}
