import React from "react";
import "../assets/css/sidermenu.css";
import "../assets/js/sidermenu.js";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Outlet } from "react-router-dom";

export function Admin() {

  return (

    // <Row className="overflowauto" style={{ height: "100%", padding: 30, paddingTop: 0, overflowY: "hidden" }}>
      <Outlet />
    // </Row>

  );

}