import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Workinggroup } from "../Types/Workinggroup.ts";
import * as Yup from "yup";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, Col, Row } from "react-bootstrap";
import { addAsync } from "../Services/WorkingGroupService.ts";
import UserContext from "../Contexts/UserContext.ts";
import WorkingGroupStatus from "../Types/WorkingGroupStatus.ts";
import Jurisdiction from "../Types/Jurisdiction.js";
import { GetJurisdictions, GetWorkingGroupStatuses } from "../Services/GetDropDownsData.ts";
import { CheckBox } from "@mui/icons-material";
import dayjs from "dayjs";
import { Texteditor } from "./Texteditor.jsx";
import LegislativeIssues from "../Types/LegislativeIssues.js";
import Textarea from "@mui/joy/Textarea/Textarea.js";


export default function WorkingGroupForm({ closePopModel, initialVal, onChangeHandeler, submitData, isReadonly = false }) {

  const userinfo = useContext(UserContext);
  const [statuses, setStatuses] = useState<WorkingGroupStatus[]>([]);
  const [jurisdictions, setJurisdiction] = useState<Jurisdiction[]>([]);
  const editorRef = useRef<any>();

  const setEditorContent = (values: Workinggroup) => {

    values.content = editorRef.current.getContent();

  };

  useEffect(() => {

    GetJurisdictions(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          var data = (resultData.data.result as Jurisdiction[]).filter(it => it != null && it.name != null && it.name != '');
          setJurisdiction(data.sort((a, b) => {
            if (a.order !== b.order) {
              return a.order - b.order;
            } else {
              return a.name.localeCompare(b.name);
            }
          }));
        }
      })
      .catch(error => console.log(error));

    GetWorkingGroupStatuses(userinfo.accessToken)
      .then(response =>
        response.json()
      )
      .then(resultData => {
        if (resultData && resultData.isSuccess) {
          setStatuses(resultData.data.result.sort((a, b) => {
            if (a.order !== b.order) {
              return a.order - b.order;
            } else {
              return a.name.localeCompare(b.name);
            }
          }));
        }
      })
      .catch(error => console.log(error));


  }, []);


  // useEffect(()=>{setVal(data)},[]);

  const validationSchema = Yup.object<Workinggroup>().shape({
    subject: Yup.string().required(),
    content: Yup.string().required(),
    statusId: Yup.number().moreThan(0).required(),
    jurisdictionIds: Yup.array().required().min(1),
    // jurisdiction: Yup.array().required().min(1),
    openDate: Yup.date().required(),
    closedDate: Yup.date().nullable()
  });

  const [openDatebox, setOpenDatebox] = useState(false);
  const [closeDatebox, setCloseDatebox] = useState(false);


  return <>
    <Formik
      initialValues={initialVal}
      validationSchema={validationSchema}
      onSubmit={(values) => { submitData(values); }}
    >
      {({ values, errors, touched, isValid, dirty, setValues, handleChange, handleBlur }) => (
        <>
          <Form id="wkgForm">
            <Row>
              <div className="padding10 col-md-9" >
                <FormControl fullWidth  >
                  <TextField
                    error={errors.subject && touched.subject}
                    value={values.subject}
                    label="Subject"
                    name="subject"
                    onChange={handleChange}
                    id="outlined-size-small"
                    size="small"
                    InputProps={
                      {
                        className: "font12 ctrlsize"
                      }}
                    disabled={isReadonly}
                  />
                </FormControl>
              </div>

              <div className="padding10 col-md-3" >
                <FormControl fullWidth error={errors.statusId && touched.statusId} >
                  <InputLabel id="outlined-size-small-label">Status</InputLabel>
                  <Select
                    size="small"
                    labelId="outlined-size-small-label"
                    id="outlined-size-small"
                    value={values.statusId}
                    label="Status"
                    name="statusId"
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={(data) => { console.log(data); handleChange(data); }}
                    disabled={isReadonly}
                  >
                    {
                      statuses.map(it => (
                        <MenuItem value={it.id}>{it.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="padding10 col-md-12" >
                <FormControl fullWidth >
                  <label className="font12" htmlFor="subject-id">Content</label>
                  {/* <Texteditor height={200} editorRef={editorRef} value={values.content}/>                 */}

                  <Textarea
                    color={touched.content && errors.content !== undefined ? "danger" : "neutral"}
                    maxRows={10}
                    minRows={10}
                    onBlur={handleBlur}
                    value={values.content}
                    onChange={handleChange}
                    size="sm"
                    name="content"
                    placeholder="Content"
                    variant="soft"
                    slotProps={{
                      textarea: {
                        id: 'content-id',
                      }
                    }}
                    disabled={isReadonly}
                  />
                </FormControl>
              </div>
              <div className="padding10 col-md-6" >
                <FormControl fullWidth >
                  <InputLabel id="demo-multiple-checkbox-label" className="labelbgwhite">Jurisdiction</InputLabel>
                  <Select
                    size="small"
                    error={errors.jurisdictionIds && touched.jurisdictionIds}
                    labelId="demo-multiple-checkbox-label"
                    id="jurisdiction"
                    multiple
                    name="jurisdictionIds"
                    value={values.jurisdictionIds}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => jurisdictions.filter(it => values.jurisdictionIds && values.jurisdictionIds.indexOf(it.id) > -1).map(it => it.name).join(', ')}
                    // MenuProps={MenuProps}
                    disabled={isReadonly}
                  >
                    {jurisdictions.map((option) => (
                      <MenuItem key={option.id} value={option.id} style={{ padding: 0, paddingLeft: 16 }}>
                        <Checkbox checked={values.jurisdictionIds.indexOf(option.id) > -1} style={{ padding: 6 }} />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="padding10 col-md-3" >
                <FormControl id="searchdate" fullWidth>
                  <DatePicker
                    label="Open Date"
                    open={openDatebox}
                    onOpen={() => setOpenDatebox(true)}
                    onClose={() => setOpenDatebox(false)}
                    value={values.openDate ? dayjs(values.openDate) : null}
                    onChange={(date) => { values.openDate = date ?? new Date() }}
                    format="ll"
                    // inputProps={{ MenuProps: { disableScrollLock: true } }}
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        id: "opendate",
                        className: "font12",
                        error: (errors.openDate && touched.openDate),
                        onKeyDown: (e) => e.preventDefault(),
                        onClick: (e) => { setOpenDatebox(true) }
                      }
                    }}
                    disabled={isReadonly}
                  />
                </FormControl>
              </div>
              <div className="padding10 col-md-3" >
                <FormControl id="searchdate" fullWidth>
                  <DatePicker
                    label="Close Date"
                    open={closeDatebox}
                    onOpen={() => setCloseDatebox(true)}
                    onClose={() => setCloseDatebox(false)}
                    value={values.closedDate ? dayjs(values.closedDate) : null}
                    onChange={(date) => { values.closedDate = date ?? new Date() }}
                    format="ll"
                    sx={{ width: '100%' }}
                    slotProps={{

                      textField: {
                        id: "closedate",
                        className: "font12",
                        onKeyDown: (e) => e.preventDefault(),
                        onClick: (e) => { setCloseDatebox(true) }
                      }
                    }}
                    disabled={isReadonly}
                  />
                </FormControl>
              </div>
            </Row>
            <Row style={{ justifyContent: "right", padding: 10 }}>
              <Button size="sm" variant="warning" style={{ width: "auto" }} className="mybtn font12" onClick={closePopModel}>Cancel</Button>
              {isReadonly ? null : <Button className="mybtn font12" variant="success" style={{ width: "auto" }} type="submit" onClick={() => { console.log(values); console.log(errors) }} >Submit</Button>}
            </Row>
          </Form>
        </>
      )}
    </Formik>
  </>
}