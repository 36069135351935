import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetHistoryByIssueId } from "../Services/ApiServices.ts";
import UserContext from "../Contexts/UserContext.ts";
import { ActionDetailHistory, IssueHistoryResponse } from "../Types/IssueHistoryResponse";
import { Response } from "../Types/Response";
import { MyCard } from "../Components/MyCard";
import { IssueHistoryDisplay } from "../Components/IssueHistoryDisplay";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { CardActions, CardContent, CardHeader, Collapse, Divider, FormControl, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import Customer from "../Types/Customer.js";
import { AuthenticationResult } from "@azure/msal-common";
import { ExpandMore, ExpandMoreOutlined } from "@mui/icons-material";
import ActionDetail from "../Types/ActionDetail.js";
// import { Card,CardHeader,Typography,CardContent } from "@mui/material";

export default function IssueHistory() {

    const { id } = useParams();
    const [issueHistory, setIssueHistory] = useState<IssueHistoryResponse>();
    const [actionDetails, setActioDetails] = useState<ActionDetail[]>();
    const [customerId, setCustomerId] = useState('');
    const [customers, setCustomers] = useState<Customer[]>([]);

    // const [expanded, setExpanded] = React.useState(false);
    const [expandedlabel, setExpandedlabel] = React.useState(0);


    const handleExpandClick = (value) => {
        if (expandedlabel == value) {
            setExpandedlabel(0);
        }
        else {
            setExpandedlabel(value);
        }

    };

    const userinfo = useContext(UserContext);

    const navigate = useNavigate();

    const GoToIssueList = () => {
        navigate(`/issues`);
    }

    useEffect(() => {

        GetHistory();

    }, []);


    const GetHistory = useCallback(() => {
        GetHistoryByIssueId(id ?? '', userinfo.accessToken)
            .then(response => response.json())
            .then((result: Response<IssueHistoryResponse>) => {
                if (result.isSuccess) {
                    console.log("history retrived sucessfully.");
                    setIssueHistory(result.data);
                    const _customers: Customer[] = result.data?.actionHistories.map(it => it.customer) ?? [];
                    setCustomers(_customers.sort((a, b) => {
                        if (a.order !== b.order) {
                            return a.order - b.order;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    }))
                    // onChangeCustomer(_customers ?_customers[0].id.toString():'')
                }
                else {
                    console.log(result.message);
                }
            })
            .catch(error => { console.log(error) });
    }, [issueHistory])

    useEffect(() => {
        onChangeCustomer(customers ? customers[0]?.id.toString() : '')
    }, [customers]);

    const onChangeCustomer = (id) => {
        setCustomerId(id);
        setActioDetails(issueHistory?.actionHistories.find(it => it.customer.id == id)?.
            actionDetails.sort((a, b) => Date.parse(b.createdDate.toString()) - Date.parse(a.createdDate.toString())));
    }

    return (
        <>
            <Row>
                <h1 className="pageHeading ">{issueHistory?.shortSubject}</h1>
            </Row>
            <Row className="boxborder" style={{ height: "inherit" }}>
                <Row className="" style={{ height: "inherit" }}>
                    <Col style={{ padding: 0, height: "inherit" }}>
                        <Row style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 10 }}>
                            <Col><h6 className="headerborderstyle">Issue History</h6></Col>
                        </Row>
                        <Row style={{ height: "inherit", overflow: "auto", overflowX: "hidden" }}>
                            {issueHistory?.issueHistories?.sort((a, b) => Date.parse(b.createdDate.toString()) - Date.parse(a.createdDate.toString())).map(data => {
                                return <Row style={{ margin: 15, paddingRight: 40, height: "fit-content" }}>
                                    <Card className="box-shadow" border="success" >
                                        <Card.Body>
                                            <Card.Title style={{ fontSize: "initial" }}>{new Date(data.createdDate).toLocaleString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })}</Card.Title>
                                            <Card.Text>
                                                {data.createdBy}
                                            </Card.Text>
                                        </Card.Body>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item><b>Status</b> : {data.status?.name}</ListGroup.Item>
                                            <ListGroup.Item><b>Action</b> : {data.action?.name}</ListGroup.Item>
                                            <ListGroup.Item><b>Enforcement Date</b> : {data.enforcementDate?new Date(data.enforcementDate).toLocaleString("en-us", { month: 'long', year: 'numeric', day: 'numeric' }):""}</ListGroup.Item>
                                            <ListGroup.Item><b>Enforcement Details</b> : {data.enforcementDetails}</ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Row>
                            })
                            }
                        </Row>
                    </Col>
                    <Col style={{ marginLeft: 15, height: "inherit", borderLeftWidth: 0.5, borderLeftColor: 'gray', borderLeftStyle: "solid" }}>
                        <Row style={{ paddingRight: 20, paddingLeft: 20 }}>
                            <Col>
                                <h6 className="headerborderstyle">Customer Action Details</h6>
                            </Col>
                            <Col>
                                <FormControl className="btnfitright" style={{ width: "40%" }}>
                                    <InputLabel id="outlined-size-small-label">Customer</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="outlined-size-small-label"
                                        id="outlined-size-small"
                                        value={customerId}
                                        label="Customer"
                                        name="customerId"
                                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                                        onChange={(data) => { onChangeCustomer(data.target.value) }}
                                    >
                                        {
                                            customers && customers.map((option) => {
                                                return <MenuItem value={option.id}>{option.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ height: "inherit", overflow: "auto", overflowX: "hidden" }}>
                            {
                                actionDetails?.map(data => {
                                    return <Row style={{ margin: 15, paddingRight: 40, height: "fit-content" }}>
                                        <Card className="box-shadow" border="success" >
                                            <Card.Body>
                                                <Card.Title style={{ fontSize: "initial" }}>{new Date(data.createdDate).toLocaleString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })}</Card.Title>
                                                <Card.Text>
                                                    {data.createdBy}
                                                </Card.Text>
                                            </Card.Body>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item><b>Action Details</b> : <p>{data.action}</p></ListGroup.Item>
                                            </ListGroup>
                                            <CardActions disableSpacing>

                                                <ExpandMore
                                                    expand={expandedlabel == data.id}
                                                    onClick={() => handleExpandClick(data.id)}
                                                    // aria-expanded={expandedlabel == history.actionDetailId}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreOutlined />
                                                </ExpandMore>
                                            </CardActions>
                                            <Collapse in={expandedlabel == data.id} timeout="auto" unmountOnExit>
                                                <label><b>History</b></label>
                                                {
                                                    data.actionDetailHistories?.sort((a, b) => Date.parse(b.created.toString()) - Date.parse(a.created.toString()))?.map(history => {
                                                        return <>
                                                            <hr />
                                                            <CardContent>
                                                                <ListItemText primary={history.createdBy} secondary={new Date(history.created).toLocaleString("en-us", { month: 'long', year: 'numeric', day: 'numeric' })} />
                                                                <ListGroup variant="flush">
                                                                    <ListGroup.Item><b>Action Details</b> : <p>{history.action}</p></ListGroup.Item>
                                                                </ListGroup>
                                                            </CardContent>
                                                        </>
                                                    })
                                                }
                                            </Collapse>
                                        </Card>
                                    </Row>
                                })

                            }
                        </Row>
                    </Col>
                </Row>
                <Row><Col style={{ paddingTop: 0, paddingBottom: 0 }}><Button className="mybtn btnfitright font12" onClick={GoToIssueList}>Back</Button></Col></Row>
            </Row>
        </>
    );
}