import React, { useContext, useState } from "react";
import IssuesForm from "./IssuesForm.tsx";
import UserContext from "../Contexts/UserContext.ts";
import LegislativeIssues from "../Types/LegislativeIssues.ts";
import { addIssue } from "../Services/ApiServices.ts";
import { useNavigate } from "react-router-dom";
import { VariantType, useSnackbar } from "notistack";

export default function AddIssue(){
    const navigation = useNavigate();
    const goBack = () => {
        navigation("/");
    }

    const userinfo = useContext(UserContext);

    const [initialValues,setinitialValues]=useState<LegislativeIssues>({
        aimid: 0,
        id: 0,
        updatedBy: '',
        createdDate: '',
        updatedDate: null,
        typeofEmergingIssue: { id: 0, name: '' },
        emergingIssueId: 0,
        region: { regionId: 0, name: '' },
        regionId: 0,
        jurisdictionIds: [],
        jurisdictions:[],
        countryId: 0,
        state_Province: "",
        county_Districts: "",
        city_Municipalities: "",
        keyword: [],
        keywordIds: [],
        subject: "",
        actionDetails: [],
        enforcementDate: null,
        enforcementDetails: "",
        action: { id: 0, name: '' },
        actionId: 0,
        category: [],
        categoryIds: [],
        impactforServiceLineIds: [],
        impactforServiceLine: [],
        url: "",
        additionalURL:"",
        businessUnitId:0,
        status: { id: 0, name: '' },
        statusId: 0,
        opentoCustomer: true,
        createdBy: userinfo?.account?.username ?? '',
        dueDate: null,
        legislativeStatus: { id: 0, name: '' },
        legislativeStatusId: 0,
        customerParticipationId: 0,
        customerParticipation: { id: 0, name: '' },
    });

    const handleSave = (issueData: LegislativeIssues) => {
        issueData.createdDate = new Date().toISOString();
        issueData.updatedDate = new Date().toISOString();

        addIssue(issueData, userinfo.accessToken)
            .then(response =>
                response.json()
            )
            .then(resultData => {
                if (resultData && resultData.isSuccess) {
                    console.log("Issue added succcessfully.");
                    goBack();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return<>
    <h1 className="pageHeading">Add Issue</h1>
    <IssuesForm initValues={initialValues} userinfo={userinfo} submitData={handleSave} goTo={goBack}/>
    </>
}