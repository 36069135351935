import { DeleteForever } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DeleteWorkingGroup } from "../Services/WorkingGroupService";
import { pink } from "@mui/material/colors";


export default function WorkingGroupGrid({ addmodel, data, getdatabyid, deletItem, writeExcel, isReadonly }) {
    const navigation = useNavigate();
    const jurisdictionValueGetters = (params) => {
        return params.data.workingGroupJurisdictions.map(it => it.jurisdiction.name).join(', ');
    };
    const oncellclick = (e) => {
        getdatabyid(e.data.id);
    }

    const contentdisplay = (params) => {
        var a = document.createElement('span');
        a.innerHTML = params.data.content;
        console.log(params);
        console.log(params);
        return a;
    };

    const [columnDefs,setcolumnDefs] = useState([
        { field: 'subject', sortable: true, flex: 1 },
        {
            field: 'content', flex: 1, cellRenderer: function (params) {

                return params.data.content;

            }
        },
        { field: 'status.name', headerName: "Status", flex: 0.3 },
        { field: 'workingGroupJurisdiction.jurisdiction', flex: 0.6, headerName: "Jurisdiction", valueGetter: params => jurisdictionValueGetters(params) },
        { field: 'openDate', flex: 0.36, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        { field: 'closedDate', flex: 0.36, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        {
            field: '',
            cellClass: 'leftpaddingzero',
            //  pinned: 'right', 
            width: 50, cellRenderer: (params) =><>{isReadonly ? null : <div>
                    <DeleteForever onClick={() => { deletItem(params.data.id); }} sx={{ fontSize: 16, color: pink[500] }} />
            </div>
        }</>
        }
    ]);


    useEffect(()=>{setcolumnDefs([
        { field: 'subject', sortable: true, flex: 1 },
        {
            field: 'content', flex: 1, cellRenderer: function (params) {
    
                return params.data.content;
    
            }
        },
        { field: 'status.name', headerName: "Status", flex: 0.3 },
        { field: 'workingGroupJurisdiction.jurisdiction', flex: 0.6, headerName: "Jurisdiction", valueGetter: params => jurisdictionValueGetters(params) },
        { field: 'openDate', flex: 0.36, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        { field: 'closedDate', flex: 0.36, valueGetter: params => params.data[params.colDef.field] == null ? "" : new Date(params.data[params.colDef.field]).toLocaleDateString("en-us", { month: 'short', year: 'numeric', day: 'numeric' }) },
        {
            field: 'Delete',
            cellClass: 'leftpaddingzero',
            hide:isReadonly,
            //  pinned: 'right', 
            width: 80, cellRenderer: (params) =><>{isReadonly ? null : <div>
                    <DeleteForever className="pointerCurser" onClick={() => { deletItem(params.data.id); }} sx={{ fontSize: 16, color: pink[500] }} />
            </div>
        }</>
        }
    ]) },[isReadonly]);
    

    const rowHeight = 24;
    const perPage = 14;
    return <>
        <Row style={{ justifyContent: "right" }}>
            <Col style={{ paddingTop: 0, paddingBottom: 0 }}>
                {isReadonly ? null : <Button onClick={addmodel} className="mybtn font12 btnfitright" style={{ width: "auto" }} >Add New</Button>}

                <svg className="mybtn btnfitright pointerCurser" xmlns="http://www.w3.org/2000/svg" onClick={writeExcel} viewBox="0 0 48 48" width="24px" height="24px"><path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z" /><path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z" /><path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z" /><path fill="#17472a" d="M14 24.005H29V33.055H14z" /><g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z" /><path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z" /><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z" /><path fill="#129652" d="M29 24.005H44V33.055H29z" /></g><path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z" /><path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z" /></svg>
            </Col>
        </Row>
        <Row className="gridheight" style={{ marginTop: 11 }}>
            <AgGridReact className="ag-theme-alpine mygrid"
                // defaultColDef={defaultColDef}
                rowData={data}
                animateRows={true}
                paginationPageSize={perPage}
                pagination={true}
                rowHeight={rowHeight}
                headerHeight={38}
                onCellDoubleClicked={oncellclick}
                enableBrowserTooltips={true}
                columnDefs={columnDefs}
                suppressMovableColumns={true}
            // onGridReady={OnGridReady}
            />
        </Row>
    </>
}
