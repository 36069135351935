import React, { Component, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Cancel, DeleteForever, Edit, EditNoteSharp, Save } from '@mui/icons-material';
import { blue, pink } from '@mui/material/colors';
import { IconButton } from '@mui/material';
import { UpdateDDL } from '../Services/AdminService';
import { v4 as uuid } from "uuid";

export default function CellRenderer(props: ICellRendererParams, deleteddl, update, savedata,editingStarted,editingStopped) {

  const [isEditing, setIsEdit] = useState(false);
  const [disable, setDisable] = useState(false);

  const startEditing = () => {
    props.api.startEditingCell({
      rowIndex: props.rowIndex,
      colKey: props.column!.getId(),
    });
  };

  
  useEffect(() => {
    props.api.addEventListener('rowEditingStarted', onRowEditingStarted);
    props.api.addEventListener('rowEditingStopped', onRowEditingStopped);
   props.api.addEventListener('filterChanged',()=>{ if (props.api.isAnyFilterPresent()) {
    editingStarted();
  }
  else {
    editingStopped();
  }});
    return () => {
        props.api.removeEventListener('rowEditingStarted', onRowEditingStarted);
        props.api.removeEventListener('rowEditingStopped', onRowEditingStopped);
    };
}, []);

function onRowEditingStarted(params) {
    if (props.node === params.node) {
      setIsEdit(true);
      editingStarted();
    } else {
        setDisable(true);
    }
};

function onRowEditingStopped(params) {
    if (props.node === params.node) {
        // if (isEmptyRow(params.data)) {
        //     deleteRow(true);
        // } else {
          setIsEdit(false);
      if (!props.api.isAnyFilterPresent()) {
        editingStopped();
        }
        // }
    } else {
        setDisable(false);
    }
}

  const stopEdit = (trurorfalse=false) => {
    props.api.stopEditing(trurorfalse);
  };

  const cancel = () => {
    stopEdit(true);

    if (!props.data.id) {
      props.api.applyTransaction({ remove: [props.data] });
    }
  }

  const updateddl = () => {

    var beforeData = props.data;
    console.log("from rendered before stop", props);
    props.api.stopEditing();
    // if (props.data.name && props.data.order) {
    if (props.data.id) {
      update(props.data);
    }
    else {
      savedata(props.data);
    }
    // }
    // else{
    //   props.api.startEditingCell(
    //     {
    //       rowIndex: props.rowIndex,
    //       colKey: props.column!.getId(),
    //     }
    //   );
    // }

    console.log("from rendered after stop", props.data);
  }

  const deleteoption = () => {
    if (props.data.id) {
      deleteddl(props.data);
    }
    else {
      props.api.applyTransaction({ remove: [props.data] });
    }
    stopEdit();
  }

  return (
    <span>
      {isEditing
        ?
        <>
          <IconButton>
            <Save onClick={updateddl} sx={{ color: blue[500] }} />
          </IconButton>
          <IconButton>
            <Cancel onClick={cancel} sx={{ color: pink[500] }} />
          </IconButton>
        </>
        :
        <>
          <IconButton disabled={disable} sx={{ cursor: "none", pointerEvents: isEditing ? "none" : "auto" }} >
            <EditNoteSharp onClick={startEditing} sx={{ color: blue[500] }} />
          </IconButton>
          <IconButton disabled={disable} >
            <DeleteForever onClick={deleteoption} sx={{ color: pink[500] }} />
          </IconButton>
        </>


      }
    </span>
  );
}