import React from "react";
import { Close, WarningAmberRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Button } from "react-bootstrap";



export default function WarningDialog({closedeleteconfirmation,opendeleteconfirmation,isdeleteconfirm,deleteitem,message}){

const _message = <p>Are you sure you want to <span style={{color:'red'}}>delete</span>?</p>
    return <>
       <Dialog
      disableEscapeKeyDown={true}
      onClose={(e, r) => { r && r == "backdropClick" ? opendeleteconfirmation() : closedeleteconfirmation() }}
      aria-labelledby="customized-dialog-title"
      open={isdeleteconfirm}
      fullWidth={true}
      style={{fontVariant:"all-small-caps"}}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} style={{borderBottom:'2px solid red'}}>
        <span className="font12" style={{color:'red'}}> <WarningAmberRounded/> <b>Confirmation</b></span>
        {closedeleteconfirmation ? (
          <IconButton
            aria-label="close"
            onClick={closedeleteconfirmation}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers style={{padding:25 }}>
      <p><b className="font12">{message ?? _message }</b></p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus size="sm" variant="info" className="v" onClick={() => { closedeleteconfirmation(); }}>
          Cancel
        </Button>
        <Button className="font12" size="sm" variant="danger" autoFocus onClick={() => { deleteitem(); closedeleteconfirmation() }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    </>
}